import React, { useState, useEffect } from "react";
import { createFarmer } from "../actions/farmers";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FailureAlert from "./FailureAlert";
import {
	Alert,
	AlertIcon,
	AlertDescription,
	AlertTitle,
} from "@chakra-ui/alert";
import { districtsData } from "../data/dummy";

const ProceedToFarm = ({ navigate }) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="success"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="green"
					>
						<AlertIcon boxSize="40px" mr={0} color="green" marginTop="25px" />
						<AlertTitle mt={4} mb={1} fontSize="lg">
							Farmer added successfully.
						</AlertTitle>
						<AlertDescription
							maxWidth="sm"
							fontSize="lg"
							marginBottom="13px"
							marginTop="10px"
						>
							Do you want to proceed to add farm?
						</AlertDescription>
						<div class="flex">
							<button
								class="block rounded-md mr-3 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
								onClick={() => navigate("/farmers-list")}
							>
								Cancel
							</button>
							<button
								class="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 m-auto mb-3"
								onClick={() => navigate("/add-new-farm")}
							>
								Proceed
							</button>
						</div>
					</Alert>
				</div>
			</div>
		</div>
	);
};

const AddNewFarmer = ({ changeState }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [proceedToFarm, setProceedToFarm] = useState(false);
	const [failureAlert, setFailureAlert] = useState(false);
	const [buttonState, setButtonState] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [selectedDistrict, setSelectedDistrict] = useState("");
	const [counties, setCounties] = useState([]);
	const [selectedCounty, setSelectedCounty] = useState("");
	const [subCounties, setSubCounties] = useState([]);
	const [selectedSubCounty, setSelectedSubCounty] = useState("");
	const [parishes, setParishes] = useState([]);
	const [selectedParish, setSelectedParish] = useState("");
	const [villages, setVillages] = useState([]);
	const [selectedVillage, setSelectedVillage] = useState("");
	const [farmerDetails, setFarmerDetails] = useState({
		first_name: "",
		last_name: "",
		sex: "",
		date_of_birth: "",
		email: "",
		phone_number: "",
		national_id: "",
		country: "",
		district: "",
		county: "",
		sub_county: "",
		parish: "",
		village: "",
		display_picture: null,
		household_members: null,
		total_household_income: null,
		school_age_household_members: null,
		hazardous_prohibition: "True",
		hunger: null,
		date_of_registration: "",
	});
	const [gender, setGender] = useState("Male");

	useEffect(() => {
		setFarmerDetails((prev) => {
			return { ...prev, sex: gender };
		});
	}, [gender]);

	const handleChange = (e) => {
		setErrorMessage("");
		const element = document.getElementById(e.target.name);
		if (element) {
			element.classList.remove("border", "border-red-500");
		}

		const { name, value } = e.target;

		setFarmerDetails((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleDistrictChange = (e) => {
		setErrorMessage("");
		const element = document.getElementById(e.target.name);
		if (element) {
			element.classList.remove("border", "border-red-500");
		}
		const { name, value } = e.target;
		const selected = e.target.value;
		setSelectedDistrict(selected);
		setFarmerDetails((prev) => ({
			...prev,
			[name]: value,
		}));
		const districts = districtsData.find((d) => d.name === selected);
		setCounties(districts ? districts.counties : []);
		setSubCounties([]);
		setParishes([]);
		setVillages([]);
	};

	const handleCountyChange = (e) => {
		setErrorMessage("");
		const element = document.getElementById(e.target.name);
		if (element) {
			element.classList.remove("border", "border-red-500");
		}
		const { name, value } = e.target;
		const selected = e.target.value;
		setSelectedCounty(selected);
		setFarmerDetails((prev) => ({
			...prev,
			[name]: value,
		}));
		const countyData = counties.find((c) => c.name === selected);
		setSubCounties(countyData ? countyData.subCounties : []);
		setParishes([]);
		setVillages([]);
	};

	const handleSubCountyChange = (e) => {
		setErrorMessage("");
		const element = document.getElementById(e.target.name);
		if (element) {
			element.classList.remove("border", "border-red-500");
		}
		const { name, value } = e.target;
		const selected = e.target.value;
		setSelectedSubCounty(selected);
		setFarmerDetails((prev) => ({
			...prev,
			[name]: value,
		}));
		const subCountyData = subCounties.find((sc) => sc.name === selected);
		setParishes(subCountyData ? subCountyData.parishes : []);
		setVillages([]);
	};

	const handleParishChange = (e) => {
		setErrorMessage("");
		const element = document.getElementById(e.target.name);
		if (element) {
			element.classList.remove("border", "border-red-500");
		}
		const { name, value } = e.target;
		const selected = e.target.value;
		setSelectedParish(selected);
		setFarmerDetails((prev) => ({
			...prev,
			[name]: value,
		}));
		const parishData = parishes.find((p) => p.name === selected);
		setVillages(parishData ? parishData.villages : []);
	};

	const handleVillageChange = (e) => {
		setErrorMessage("");
		const element = document.getElementById(e.target.name);
		if (element) {
			element.classList.remove("border", "border-red-500");
		}
		const { name, value } = e.target;
		setSelectedVillage(e.target.value);
		setFarmerDetails((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];

		const { name } = e.target;
		setFarmerDetails((prev) => {
			return { ...prev, [name]: file };
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const requiredFields = [
			"first_name",
			"last_name",
			"date_of_birth",
			"phone_number",
			"district",
			"county",
			"sub_county",
			"parish",
			"date_of_registration",
			"household_members",
			"total_household_income",
			"school_age_household_members",
			"hazardous_prohibition",
			"hunger",
		];
		let allFieldsFilled = true;

		requiredFields.forEach((field) => {
			const element = document.getElementById(field);
			if (farmerDetails[field] === "" || farmerDetails[field] === null) {
				setErrorMessage(
					"You need to fill the missing fields with a red border!",
				);
				element?.classList.add("border", "border-red-500");
				allFieldsFilled = false;
			} else {
				element?.classList.remove("border", "border-red-500");
			}
		});

		if (allFieldsFilled) {
			setButtonState(true);
			dispatch(
				createFarmer(
					{
						...farmerDetails,
						date_of_birth: `${farmerDetails.date_of_birth}T12:00:00`,
						date_of_registration: `${farmerDetails.date_of_registration}T12:00:00`,
					},
					setProceedToFarm,
					setFailureAlert,
					setButtonState,
				),
			);
		}
		// console.log("test", {
		// 	...farmerDetails,
		// 	date_of_birth: `${farmerDetails.date_of_birth}T12:00:00`,
		// 	date_of_registration: `${farmerDetails.date_of_registration}T12:00:00`,
		// });
	};

	return (
		<>
			<div className="relative flex flex-col min-w-0 break-words w-full mb-6 mt-10 rounded-lg bg-blueGray-100 border-0">
				<div className="rounded-t bg-white mb-0 px-6 py-6">
					<div className="text-center flex justify-between">
						<h6 className="text-blueGray-700 text-xl font-bold">
							Add New Farmer Details
						</h6>
					</div>
				</div>
				<div className="flex-auto px-4 lg:px-10 py-10 pt-0">
					<form>
						<div className="flex flex-wrap mt-4">
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										First Name
									</label>
									<input
										type="text"
										name="first_name"
										id="first_name"
										value={farmerDetails.first_name}
										onChange={handleChange}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Last Name
									</label>
									<input
										type="text"
										name="last_name"
										id="last_name"
										onChange={handleChange}
										value={farmerDetails.last_name}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Date of Birth
									</label>
									<input
										type="date"
										name="date_of_birth"
										id="date_of_birth"
										onChange={handleChange}
										value={farmerDetails.date_of_birth}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Sex
									</label>
									<div className="relative">
										<select
											className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-state"
											value={gender}
											onChange={(e) => setGender(e.target.value)}
										>
											<option>Male</option>
											<option>Female</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
							</div>
							<div className="w-full px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Email
									</label>
									<input
										type="email"
										value={farmerDetails.email}
										onChange={handleChange}
										name="email"
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Phone Number
									</label>
									<input
										type="text"
										name="phone_number"
										id="phone_number"
										value={farmerDetails.phone_number}
										onChange={handleChange}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										National ID Number(NIN)
									</label>
									<input
										type="text"
										name="national_id"
										id="national_id"
										value={farmerDetails.national_id}
										onChange={handleChange}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Country
									</label>
									<select
										name="country"
										id="country"
										value={farmerDetails.country}
										onChange={handleChange}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									>
										<option value="">Select Country</option>
										<option value="Uganda">Uganda</option>
									</select>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										District
									</label>
									<select
										name="district"
										id="district"
										value={selectedDistrict}
										onChange={handleDistrictChange}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									>
										<option value="">Select District</option>
										{districtsData.map((district, index) => (
											<option key={index} value={district.name}>
												{district.name}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										County
									</label>
									<select
										name="county"
										id="county"
										value={selectedCounty}
										onChange={handleCountyChange}
										disabled={!counties.length}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									>
										<option value="">Select County</option>
										{counties.map((county, index) => (
											<option key={index} value={county.name}>
												{county.name}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Sub County
									</label>
									<select
										name="sub_county"
										id="sub_county"
										value={selectedSubCounty}
										onChange={handleSubCountyChange}
										disabled={!subCounties.length}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									>
										<option value="">Select Sub County</option>
										{subCounties.map((subCounty, index) => (
											<option key={index} value={subCounty.name}>
												{subCounty.name}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Parish
									</label>
									<select
										name="parish"
										id="parish"
										value={selectedParish}
										onChange={handleParishChange}
										disabled={!parishes.length}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									>
										<option value="">Select Parish</option>
										{parishes.map((parish, index) => (
											<option key={index} value={parish.name}>
												{parish.name}
											</option>
										))}
									</select>
								</div>
							</div>

							{/* <div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Village
									</label>
									<select
										name="village"
										id="village"
										value={selectedVillage}
										onChange={handleVillageChange}
										disabled={!villages.length}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									>
										<option value="">Select Village</option>
										{villages.map((village, index) => (
											<option key={index} value={village}>
												{village}
											</option>
										))}
									</select>
								</div>
							</div> */}

							<div className="w-full px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Farmer Image
									</label>
									<input
										type="file"
										// accept="image/*"
										name="display_picture"
										onChange={handleFileChange}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Household Members
									</label>
									<input
										type="number"
										name="household_members"
										id="household_members"
										value={farmerDetails.household_members}
										onChange={handleChange}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Total Household Income
									</label>
									<input
										type="number"
										name="total_household_income"
										id="total_household_income"
										value={farmerDetails.total_household_income}
										onChange={handleChange}
										step="0.01"
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										School Age Household Members
									</label>
									<input
										type="number"
										name="school_age_household_members"
										id="school_age_household_members"
										value={farmerDetails.school_age_household_members}
										onChange={handleChange}
										step="0.1"
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							{/* <div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Hazardous Prohibition
									</label>
									<input
										type="text"
										name="hazardous_prohibition"
										id="hazardous_prohibition"
										value={farmerDetails.hazardous_prohibition}
										onChange={handleChange}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div> */}
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Hazardous Prohibition
									</label>
									<div className="relative">
										<select
											className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											name="hazardous_prohibition"
											id="hazardous_prohibition"
											value={farmerDetails.hazardous_prohibition}
											onChange={handleChange}
										>
											<option value="True">Yes</option>
											<option value="False">No</option>
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Hunger Days
									</label>
									<input
										type="number"
										name="hunger"
										id="hunger"
										value={farmerDetails.hunger}
										onChange={handleChange}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>

							<div className="w-full lg:w-6/12 px-4">
								<div className="relative w-full mb-3">
									<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Farmer Registration Date
									</label>
									<input
										type="date"
										name="date_of_registration"
										id="date_of_registration"
										onChange={handleChange}
										value={farmerDetails.date_of_registration}
										className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
						</div>
					</form>
					<div className="">
						<p className="text-sm text-red-600">
							{errorMessage && errorMessage}
						</p>
					</div>
					<button
						className="bg-blue-500 hover:bg-blue-700 text-white ml-4 py-2 px-4 mb-8"
						onClick={handleSubmit}
						disabled={buttonState}
					>
						Save
					</button>
				</div>
			</div>
			{proceedToFarm ? <ProceedToFarm navigate={navigate} /> : ""}
			{failureAlert ? <FailureAlert setFailureAlert={setFailureAlert} /> : ""}
		</>
	);
};

export default AddNewFarmer;
