import { IoMdContacts } from 'react-icons/io';
import { RiContactsLine, RiStockLine } from 'react-icons/ri';
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { TiTick } from 'react-icons/ti';
import { GiLouvrePyramid } from 'react-icons/gi';
import { GrLocation } from 'react-icons/gr';
import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock, AiOutlineHome, AiOutlineHeatMap } from 'react-icons/ai';
import {
  FiShoppingBag,
  //  FiEdit, 
  FiPieChart, FiBarChart, FiCreditCard, FiStar, FiShoppingCart
} from 'react-icons/fi';


export const links = [

  {
    title: 'Pages',
    links: [
      {
        name: 'dashboard',
        icon: <AiOutlineHome />,
      },
      {
        name: 'purchases',
        icon: <IoMdContacts />,
      },
    ],
  },
  {
    title: 'Farmers',
    links: [
      {
        name: 'mapview',
        icon: <AiOutlineHeatMap />,
      },
      {
        name: 'farmers',
        icon: <IoMdContacts />,
      },
    ],
  },
  // {
  //   title: 'Charts',
  //   links: [
  //     {
  //       name: 'line',
  //       icon: <AiOutlineStock />,
  //     },
  //     {
  //       name: 'area',
  //       icon: <AiOutlineAreaChart />,
  //     },

  //     {
  //       name: 'bar',
  //       icon: <AiOutlineBarChart />,
  //     },
  //     {
  //       name: 'pie',
  //       icon: <FiPieChart />,
  //     },
  //     {
  //       name: 'financial',
  //       icon: <RiStockLine />,
  //     },
  //     {
  //       name: 'color-mapping',
  //       icon: <FiBarChart />,
  //     },
  //     {
  //       name: 'pyramid',
  //       icon: <GiLouvrePyramid />,
  //     },
  //     {
  //       name: 'stacked',
  //       icon: <AiOutlineBarChart />,
  //     },
  //   ],
  // },
];

export const farmersData = [
  {
    farmerID: "1",
    firstName: "John",
    lastName: "Smith",
    gender: "Male",
    dateOfBirth: "1985-07-15",
    phoneNumber: "123-456-7890",
    address: "123 Main St, Anytown",
    farmName: "Green Acres",
    location: { lat: 40.7128, lng: -74.0060 },
    size: "50 acres",
    soilType: "Clay",
    ownershipStatus: "Owned"
  },
  {
    farmerID: "2",
    firstName: "Emily",
    lastName: "Johnson",
    gender: "Female",
    dateOfBirth: "1990-03-20",
    phoneNumber: "987-654-3210",
    address: "456 Elm St, Otherville",
    farmName: "Sunny Fields",
    location: { lat: 34.0522, lng: -118.2437 },
    size: "100 hectares",
    soilType: "Loamy",
    ownershipStatus: "Rented"
  },
  {
    farmerID: "3",
    firstName: "Michael",
    lastName: "Williams",
    gender: "Male",
    dateOfBirth: "1978-11-05",
    phoneNumber: "456-789-0123",
    address: "789 Oak St, Smalltown",
    farmName: "Rolling Hills",
    location: { lat: 41.8781, lng: -87.6298 },
    size: "75 acres",
    soilType: "Sandy",
    ownershipStatus: "Owned"
  },
  {
    farmerID: "4",
    firstName: "Jessica",
    lastName: "Brown",
    gender: "Female",
    dateOfBirth: "1982-09-10",
    phoneNumber: "321-654-0987",
    address: "987 Pine St, Village",
    farmName: "Pleasant View",
    location: { lat: 37.7749, lng: -122.4194 },
    size: "120 hectares",
    soilType: "Clay",
    ownershipStatus: "Owned"
  },
  {
    farmerID: "5",
    firstName: "David",
    lastName: "Jones",
    gender: "Male",
    dateOfBirth: "1970-05-25",
    phoneNumber: "111-222-3333",
    address: "654 Maple St, Hamlet",
    farmName: "Golden Fields",
    location: { lat: 51.5074, lng: -0.1278 },
    size: "80 acres",
    soilType: "Loamy",
    ownershipStatus: "Rented"
  },
  {
    farmerID: "6",
    firstName: "Sarah",
    lastName: "Miller",
    gender: "Female",
    dateOfBirth: "1988-12-30",
    phoneNumber: "444-555-6666",
    address: "789 Pine St, Town",
    farmName: "Sunflower Farm",
    location: { lat: 37.7749, lng: -122.4194 },
    size: "60 hectares",
    soilType: "Sandy",
    ownershipStatus: "Owned"
  },
  {
    farmerID: "7",
    firstName: "James",
    lastName: "Davis",
    gender: "Male",
    dateOfBirth: "1995-02-14",
    phoneNumber: "777-888-9999",
    address: "159 Oak St, Countryside",
    farmName: "Green Pastures",
    location: { lat: 52.5200, lng: 13.4050 },
    size: "90 acres",
    soilType: "Clay",
    ownershipStatus: "Rented"
  },
  {
    farmerID: "8",
    firstName: "Amanda",
    lastName: "Wilson",
    gender: "Female",
    dateOfBirth: "1980-08-18",
    phoneNumber: "666-777-8888",
    address: "357 Cedar St, Farmland",
    farmName: "Sunny Farms",
    location: { lat: 40.7128, lng: -74.0060 },
    size: "110 hectares",
    soilType: "Loamy",
    ownershipStatus: "Owned"
  },
  {
    farmerID: "9",
    firstName: "Robert",
    lastName: "Martinez",
    gender: "Male",
    dateOfBirth: "1973-04-03",
    phoneNumber: "555-444-3333",
    address: "753 Oak St, Ruralville",
    farmName: "Happy Acres",
    location: { lat: 34.0522, lng: -118.2437 },
    size: "70 acres",
    soilType: "Sandy",
    ownershipStatus: "Rented"
  },
  {
    farmerID: "10",
    firstName: "Jennifer",
    lastName: "Anderson",
    gender: "Female",
    dateOfBirth: "1984-06-20",
    phoneNumber: "999-888-7777",
    address: "124 Maple St, Uptown",
    farmName: "Breezy Meadows",
    location: { lat: 41.8781, lng: -87.6298 },
    size: "130 hectares",
    soilType: "Clay",
    ownershipStatus: "Owned"
  },
  {
    farmerID: "11",
    firstName: "Christopher",
    lastName: "Taylor",
    gender: "Male",
    dateOfBirth: "1976-10-12",
    phoneNumber: "123-987-4560",
    address: "258 Elm St, Riverside",
    farmName: "Maple Grove",
    location: { lat: 37.7749, lng: -122.4194 },
    size: "85 acres",
    soilType: "Loamy",
    ownershipStatus: "Owned"
  },
  {
    farmerID: "12",
    firstName: "Melissa",
    lastName: "Rodriguez",
    gender: "Female",
    dateOfBirth: "1992-01-28",
    phoneNumber: "111-222-3333",
    address: "741 Cedar St, Lakeside",
    farmName: "Meadowlands",
    location: { lat: 34.0522, lng: -118.2437 },
    size: "55 hectares",
    soilType: "Sandy",
    ownershipStatus: "Rented"
  },
  {
    farmerID: "13",
    firstName: "Daniel",
    lastName: "Lee",
    gender: "Male",
    dateOfBirth: "1987-03-17",
    phoneNumber: "333-444-5555",
    address: "963 Pine St, Hillside",
    farmName: "Green Acres",
    location: { lat: 40.7128, lng: -74.0060 },
    size: "95 acres",
    soilType: "Clay",
    ownershipStatus: "Owned"
  },
  {
    farmerID: "14",
    firstName: "Kimberly",
    lastName: "White",
    gender: "Female",
    dateOfBirth: "1981-12-08",
    phoneNumber: "666-555-4444",
    address: "852 Oak St, Hilltop",
    farmName: "Hillcrest Farm",
    location: { lat: 41.8781, lng: -87.6298 },
    size: "75 hectares",
    soilType: "Loamy",
    ownershipStatus: "Owned"
  },
  {
    farmerID: "15",
    firstName: "Matthew",
    lastName: "Harris",
    gender: "Male",
    dateOfBirth: "1979-08-22",
    phoneNumber: "999-888-7777",
    address: "369 Maple St, Brookside",
    farmName: "Sunflower Farm",
    location: { lat: 37.7749, lng: -122.4194 },
    size: "100 acres",
    soilType: "Sandy",
    ownershipStatus: "Rented"
  }
];

export const districtsData = [
  {
    "name": "Rukungiri",
    "counties": [
      {
        "name": "Rujumbura County",
        "subCounties": [
          {
            "name": "Nyakagyeme",
            "parishes": [
              {
                "name": "Nyakagyeme Parish",
                "villages": ["Kahoko", "Kasoroza", "Kashenshero"]
              },
              {
                "name": "Kashayo Parish",
                "villages": ["Nyakibale", "Kagunga", "Rwentondo"]
              }
            ]
          },
        ]
      },
      {
        "name": "Rubabo County",
        "subCounties": [
          {
            "name": "Nyakishenyi",
            "parishes": [
              {
                "name": "Bikongozo",
                "villages": ["Buyanja", "Kihanga", "Kihihi"]
              },
              {
                "name": "Kacence",
                "villages": ["Kashenshero", "Kibatsi", "Rwarinda"]
              },
              {
                "name": "Kafunjo",
                "villages": ["Kashenshero", "Kibatsi", "Rwarinda"]
              },
              {
                "name": "Kahoko",
                "villages": ["Kashenshero", "Kibatsi", "Rwarinda"]
              },
              {
                "name": "Katonya",
                "villages": ["Kashenshero", "Kibatsi", "Rwarinda"]
              },
              {
                "name": "Murama",
                "villages": ["Kashenshero", "Kibatsi", "Rwarinda"]
              },
              {
                "name": "Ngoma",
                "villages": ["Kashenshero", "Kibatsi", "Rwarinda"]
              },
              {
                "name": "Nyarugando",
                "villages": ["Kashenshero", "Kibatsi", "Rwarinda"]
              },
              {
                "name": "Rwanyundo",
                "villages": ["Kashenshero", "Kibatsi", "Rwarinda"]
              },
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Kanungu",
    "counties": [
      {
        "name": "Kinkizi County East",
        "subCounties": [
          {
            "name": "Katete",
            "parishes": [
              {
                "name": "Kishuro",
                "villages": ["Nyamirama", "Kihihi", "Nyakagyezi"]
              },
              {
                "name": "Kayanja",
                "villages": ["Nyamirama", "Kihihi", "Nyakagyezi"]
              },
              {
                "name": "Nyarurambi",
                "villages": ["Nyamirama", "Kihihi", "Nyakagyezi"]
              },
              {
                "name": "Nyakishojwa",
                "villages": ["Nyamirama", "Kihihi", "Nyakagyezi"]
              }
            ]
          },
          {
            "name": "Kihanda",
            "parishes": [
              {
                "name": "Rwenkyende",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Nyakibuga",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Nyakatoma",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Bujerengye",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kirima",
            "parishes": [
              {
                "name": "Rubimbwa",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Bushura",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Rutugunda",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kinaaba",
            "parishes": [
              {
                "name": "Mukirwa",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kyamukombe",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kiziba",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kanyamatembe",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kamakoma",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Rutenga",
            "parishes": [
              {
                "name": "Muramba",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Mafuga",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Katojo",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Bugongi",
            "parishes": [
              {
                "name": "Rushebeya",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kakinga",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Ihembe",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Buziniro",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kambuga",
            "parishes": [
              {
                "name": "Nyarutojo",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Nyarugunda",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kiringa",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kambuga Town Council",
            "parishes": [
              {
                "name": "Southern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Northern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Eastern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Central Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kanungu Town Council",
            "parishes": [
              {
                "name": "Southern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Northern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Eastern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Central Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Nyakabungo Town Council",
            "parishes": [
              {
                "name": "Southern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Northern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Eastern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Central Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Rutenga Town Council",
            "parishes": [
              {
                "name": "Southern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Northern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Eastern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Central Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Rugyeyo",
            "parishes": [
              {
                "name": "Nyarurambi",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Mishenyi",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kayungwe",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Katungu",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kashojwa",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          }
        ]
      },
      {
        "name": "Kinkizi County West",
        "subCounties": [
          {
            "name": "Nyanga",
            "parishes": [
              {
                "name": "Nyanga",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Nkunda",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kamahe",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Bukorwe",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Nyamirama Town Council",
            "parishes": [
              {
                "name": "Western Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Southern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Northern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Eastern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Nyamirama",
            "parishes": [
              {
                "name": "Mashaku",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Ntungwa",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Nyakashure",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Nyarurambi",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Rushaka",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Nyakinoni",
            "parishes": [
              {
                "name": "Kanyambeho",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Karubeizi",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Nyakinoni",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Samaria",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Mpungu",
            "parishes": [
              {
                "name": "Ngaara",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Muramba",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Mpungu",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Buremba",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kyeshero",
            "parishes": [
              {
                "name": "Rugando",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kyeshero",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kashenyi",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Bweronde",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kihiihi Town Council",
            "parishes": [
              {
                "name": "Bihomborwa Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kihiihi Town Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Nyakatunguru Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Rwanga Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kihiihi",
            "parishes": [
              {
                "name": "Kabuga",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kibimbiri",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Rusoroza",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kayonza",
            "parishes": [
              {
                "name": "Bujengwe",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Karangara",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Rutendere",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kanyantorogo Town Council",
            "parishes": [
              {
                "name": "Western Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Town Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Southern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Eastern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Kanyantorogo",
            "parishes": [
              {
                "name": "Burema",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kihembe",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Kishenyi",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Nyamigoye",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Butogota Town Council",
            "parishes": [
              {
                "name": "Eastern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Northern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Southern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Western Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          },
          {
            "name": "Buhoma Town Council",
            "parishes": [
              {
                "name": "Central Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Eastern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Northern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              },
              {
                "name": "Southern Ward",
                "villages": ["Kanyantorogo", "Kanyamugiri", "Kayonza"]
              }
            ]
          }
        ]
      },
    ]
  },
  {
    "name": "Fort Portal",
    "counties": [
      {
        "name": "Burahya",
        "subCounties": [
          {
            "name": "Karambi",
            "parishes": [
              {
                "name": "Karambi Parish",
                "villages": ["Kyembogo", "Kasusu", "Kicwamba"]
              },
              {
                "name": "Kibasi Parish",
                "villages": ["Kibasi", "Kihondo", "Kiko"]
              }
            ]
          },
          {
            "name": "Kabarole",
            "parishes": [
              {
                "name": "Kabarole Parish",
                "villages": ["Kabarole", "Kabende", "Kasenda"]
              }
            ]
          }
        ]
      },
      {
        "name": "Bunyangabu",
        "subCounties": [
          {
            "name": "Kibiito",
            "parishes": [
              {
                "name": "Kibiito Parish",
                "villages": ["Kibiito", "Kyarusozi", "Rwaihamba"]
              },
              {
                "name": "Nyantabooma Parish",
                "villages": ["Nyantabooma", "Kiboota", "Rugarama"]
              }
            ]
          }
        ]
      }
    ]
  }
]

