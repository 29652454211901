import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { deactivateUser, reactivateUser, updateUser } from "../api";
import { useStateContext } from "../contexts/ContextProvider";
import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/alert";
import SuccessAlert from "./SuccessAlert";
import FailureAlert from "./FailureAlert";

const ConfirmDeactivation = ({
	setConfirmDeactivateModal,
	handleDeactivate,
}) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="error"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="red"
					>
						<AlertIcon boxSize="40px" mr={0} marginTop="25px" />
						<AlertDescription
							maxWidth="sm"
							fontSize="lg"
							marginBottom="13px"
							marginTop="10px"
						>
							Are you sure you want to deactivate this user?
						</AlertDescription>
						<div class="flex">
							<button
								class="block rounded-md mr-3 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
								onClick={() => setConfirmDeactivateModal(false)}
							>
								Cancel
							</button>
							<button
								class="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 m-auto mb-3"
								onClick={() => handleDeactivate()}
							>
								Yes
							</button>
						</div>
					</Alert>
				</div>
			</div>
		</div>
	);
};

const ConfirmReactivation = ({
	setConfirmReactivateModal,
	handleReactivate,
}) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="error"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="orange"
					>
						<AlertIcon boxSize="40px" mr={0} marginTop="25px" />
						<AlertDescription
							maxWidth="sm"
							fontSize="lg"
							marginBottom="13px"
							marginTop="10px"
						>
							Are you sure you want to activate this user?
						</AlertDescription>
						<div class="flex">
							<button
								class="block rounded-md mr-3 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
								onClick={() => setConfirmReactivateModal(false)}
							>
								Cancel
							</button>
							<button
								class="block rounded-md mr-3 bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 m-auto mb-3"
								onClick={() => handleReactivate()}
							>
								Yes
							</button>
						</div>
					</Alert>
				</div>
			</div>
		</div>
	);
};

const ConfirmUpdate = ({ setConfirmUpdateModal, handleUserUpdate }) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="error"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="blue"
					>
						<AlertIcon boxSize="40px" mr={0} marginTop="25px" />
						<AlertDescription
							maxWidth="sm"
							fontSize="lg"
							marginBottom="13px"
							marginTop="10px"
						>
							Are you sure you want to update this user data?
						</AlertDescription>
						<div class="flex">
							<button
								class="block rounded-md mr-3 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
								onClick={() => setConfirmUpdateModal(false)}
							>
								Cancel
							</button>
							<button
								class="block rounded-md mr-3 bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 m-auto mb-3"
								onClick={() => handleUserUpdate()}
							>
								Yes
							</button>
						</div>
					</Alert>
				</div>
			</div>
		</div>
	);
};

function Profile() {
	const { companyEmployees, setCompanyEmployees } = useStateContext();
	const location = useLocation();
	const { person, personIndex } = location.state || [];
	const [confirmDeactivateModal, setConfirmDeactivateModal] = useState(false);
	const [confirmReactivateModal, setConfirmReactivateModal] = useState(false);
	const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
	const [successAlert, setSuccessAlert] = useState(false);
	const [failureAlert, setFailureAlert] = useState(false);

	const [profileData, setProfile] = useState({});
	useEffect(() => {
		setProfile(person);
	}, [person]);

	const handleDeactivate = async () => {
		let id = profileData.user_id;
		try {
			await deactivateUser(id);
			setSuccessAlert(true);
			setProfile((prev) => {
				return { ...prev, active: false };
			});
			setCompanyEmployees((prev) => {
				return [...prev, (companyEmployees[personIndex] = profileData)];
			});
			setConfirmDeactivateModal(false);
		} catch (error) {
			console.log(error);
			setFailureAlert(true);
		}
	};

	const handleReactivate = async () => {
		let id = profileData.user_id;
		try {
			await reactivateUser(id);
			setSuccessAlert(true);
			setProfile((prev) => {
				return { ...prev, active: true };
			});
			setCompanyEmployees((prev) => {
				return [...prev, (companyEmployees[personIndex] = profileData)];
			});
			setConfirmReactivateModal(false);
		} catch (error) {
			console.log(error);
			setFailureAlert(true);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setProfile((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const handleUserUpdate = async () => {
		let id = profileData.user_id;
		try {
			await updateUser(id, profileData);
			// console.log(profileData);
			setConfirmUpdateModal(false);
			setSuccessAlert(true);
		} catch (error) {
			console.log(error);
			setFailureAlert(true);
		}
	};

	return (
		<>
			<div class="relative flex flex-col min-w-0 break-words w-auto mb-6 rounded-lg bg-blueGray-100 border-0">
				<div class="rounded-t bg-white mb-0 px-6 py-6">
					<div class="text-center flex justify-between">
						<h6 class="text-blueGray-700 text-3xl pl-5 text-sky-800 font-bold">
							{`${profileData?.first_name}'s Profile`}
						</h6>
					</div>
				</div>
				<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
					<form>
						<div class="flex flex-wrap mt-4">
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
										First Name
									</label>
									<input
										type="text"
										name="first_name"
										value={profileData?.first_name}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Last Name
									</label>
									<input
										type="text"
										name="last_name"
										onChange={handleChange}
										value={profileData?.last_name}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>

							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block text-sky-800 uppercase text-blueGray-600 text-xs font-bold mb-2">
										Email
									</label>
									<input
										type="email"
										value={profileData?.email}
										onChange={handleChange}
										name="email"
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>

							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold text-sky-800 mb-2">
										Phone Number
									</label>
									<input
										type="text"
										name="phone_number"
										value={profileData?.phone_number}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
						</div>
					</form>
					{confirmDeactivateModal ? (
						<ConfirmDeactivation
							setConfirmDeactivateModal={setConfirmDeactivateModal}
							handleDeactivate={handleDeactivate}
						/>
					) : (
						""
					)}
					{confirmReactivateModal ? (
						<ConfirmReactivation
							setConfirmReactivateModal={setConfirmReactivateModal}
							handleReactivate={handleReactivate}
						/>
					) : (
						""
					)}
					{confirmUpdateModal ? (
						<ConfirmUpdate
							setConfirmUpdateModal={setConfirmUpdateModal}
							handleUserUpdate={handleUserUpdate}
						/>
					) : (
						""
					)}
					{successAlert ? (
						<SuccessAlert setSuccessAlert={setSuccessAlert} />
					) : (
						""
					)}
					{failureAlert ? (
						<FailureAlert setFailureAlert={setFailureAlert} />
					) : (
						""
					)}
					<div class="flex pt-3">
						{profileData?.active ? (
							<button
								class="bg-red-800 hover:bg-red-500 text-white ml-4 py-2 px-4 mb-8"
								// onClick={() => handleDeactivate(profileData.user_id)}
								onClick={() => setConfirmDeactivateModal(true)}
							>
								Deactivate Account
							</button>
						) : (
							<button
								class="bg-green-600 hover:bg-green-400 text-white ml-4 py-2 px-4 mb-8"
								// onClick={() => handleReactivate(profileData.user_id)}
								onClick={() => setConfirmReactivateModal(true)}
							>
								Activate Account
							</button>
						)}
						<button
							class="bg-blue-500 hover:bg-blue-700 text-white ml-4 py-2 px-4 mb-8"
							// onClick={() => handleUserUpdate(profileData.user_id)}
							onClick={() => setConfirmUpdateModal(true)}
						>
							Update User Details
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default Profile;
