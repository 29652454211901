import React from 'react'
import FarmerDetails from '../components/FarmerDetails'

const FarmerDetailsPage = () => {
  return (
    <div>
        <FarmerDetails />
    </div>
  )
}

export default FarmerDetailsPage