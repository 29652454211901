import React, { createContext, useContext, useReducer, useState } from "react";

const StateContext = createContext();

const initialState = {
	chat: false,
	cart: false,
	userProfile: false,
	notification: false,
};

export const ContextProvider = ({ children }) => {
	const serverUrl = "https://cherio-track-prod-fcbbe03ffafc.herokuapp.com";
	const [activeMenu, setActiveMenu] = useState(true);
	const [isClicked, setIsClicked] = useState(initialState);
	const [screenSize, setScreenSize] = useState(undefined);
	const [selectedFarmer, setSelectedFarmer] = useState(null);
	const [rightMenuVisibility, setRightMenuVisibility] = useState(false);
	const [open, setOpen] = useState(false);
	const [companyEmployees, setCompanyEmployees] = useState([]);
	const [polygonCoords, setPolygonCoords] = useState([]);
	const [markerCoords, setMarkerCoords] = useState([]);

	const handleClick = (clicked) => {
		setIsClicked({ ...isClicked, [clicked]: true });
	};
	return (
		<StateContext.Provider
			value={{
				activeMenu,
				setActiveMenu,
				isClicked,
				setIsClicked,
				handleClick,
				screenSize,
				setScreenSize,
				selectedFarmer,
				setSelectedFarmer,
				rightMenuVisibility,
				setRightMenuVisibility,
				markerCoords,
				setMarkerCoords,
				open,
				setOpen,
				serverUrl,
				companyEmployees,
				setCompanyEmployees,
				polygonCoords,
				setPolygonCoords,
				markerCoords,
				setMarkerCoords,
			}}
		>
			{children}
		</StateContext.Provider>
	);
};

export const useStateContext = () => useContext(StateContext);
