import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import LandingIntro from "./LandingIntro";
import ErrorText from "../components/Typography/ErrorText";
import InputText from "../components/inputs/InputText";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signin } from "../actions/auth";

const initialState = { email: "", password: "" };

function Login() {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [formData, setFormData] = useState(initialState);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const submitForm = (e) => {
		e.preventDefault();

		if (formData.email === "" || formData.password === "") {
			setErrorMessage("You need to fill the missing fields!");
		} else {
			setLoading(true);
			dispatch(signin(formData, navigate));
		}
	};

	const handleChange = (e) => {
		setErrorMessage("");
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	return (
		<div className="min-h-screen lg:-ml-72 bg-base-200 flex items-center">
			<div className="card mx-auto w-full max-w-5xl  shadow-xl">
				<div className=" bg-base-100 rounded-xl">
					<div class="w-full xl:w-full bg-white">
						<form
							class="px-8 pt-6 pb-8 mb-4 bg-white rounded"
							onSubmit={submitForm}
						>
							<img
								src="./cherio.png"
								alt="cherio-logo"
								className="block ml-auto mr-auto mb-3 mt-3"
							/>

							<h1 class=" text-2xl text-center text-sky-800 font-bold">
								Sign in to your account
							</h1>
							{/* <div class="text-center">
								<a
									class="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800 hover:underline"
									href="/register"
								>
									Don't have an acccount? Register here!
								</a>
							</div> */}
							<div className="text-center">
								<p className="text-sm text-red-600">
									{errorMessage && errorMessage}
								</p>
							</div>
							<div class="mb-4 mt-6">
								<label
									class="block text-sky-800 text-sm text-center font-semibold mb-2"
									htmlFor="email"
								>
									EMAIL
								</label>
								<input
									class="text-sm appearance-none ml-auto mr-auto block lg:w-3/4  text-center rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
									id="email"
									name="email"
									onChange={handleChange}
									type="text"
									placeholder="Your email address"
								/>
							</div>
							<div class="mb-6 mt-6">
								<label
									class="block text-sky-800 text-sm text-center font-semibold mb-2"
									htmlFor="password"
								>
									PASSWORD
								</label>
								<input
									class="text-sm bg-gray-200 ml-auto mr-auto block lg:w-3/4 appearance-none text-center rounded w-full  py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline h-10"
									id="password"
									type="password"
									name="password"
									onChange={handleChange}
									placeholder="Your password"
								/>
								<div className="block ml-auto mr-auto w-3/4 text-center mt-2">
									<a
										class="inline-block align-baseline text-sm text-sky-800 hover:text-gray-800"
										href="#"
									>
										Forgot Password?
									</a>
								</div>
							</div>
							<div class="flex w-full mt-8 ">
								<button
									class="w-1/5 block ml-auto mr-auto bg-sky-800 hover:bg-grey-900 text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10"
									type="submit"
								>
									Sign in
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
