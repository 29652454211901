import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Farmers, Purchases } from "./pages";
import { useStateContext } from "./contexts/ContextProvider";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import RegisterPage from "./pages/RegisterPage";
import OrgRegisterPage from "./pages/OrgRegisterPage";
import FarmerDetailsPage from "./pages/FarmerDetailsPage";
import FarmersMap from "./pages/FarmersMap";
import Employees from "./pages/Employees";
import Profile from "./components/Profile";
import Invitations from "./pages/Invitations";
import SignupRedirect from "./pages/SignupRedirect";
import EmailRedirectToSignin from "./pages/EmailRedirectToSignin";
import Home from "./pages/Home";
import Confirmation from "./pages/Confirmation";
import AddNewFarm from "./components/AddNewFarm";
import AddNewFarmer from "./components/AddNewFarmer";
import NewFarmersList from "./pages/NewFarmersList";
import FarmerProfile from "./pages/FarmerProfile";
import EmployeeAccount from "./components/EmployeeAccount";
import AdminAccount from "./components/AdminAccount";
import AddCetifications from "./components/AddCetifications";
import NewSideBar from "./components/NewSideBar";
import NotFoundPage from "./pages/NotFoundPage";
import AddPolygon from "./components/AddPolygon";

function App() {
	const { activeMenu } = useStateContext();

	return (
		<div>
			<BrowserRouter>
				<div className="flex relative dark:bg-main-dark-bg bg-gray-50">
					{activeMenu && (
						<div className="sidebar fixed w-72 z-10 bg-sidebar-bg dark:bg-dark-sidebar-bg">
							{/* Sidebar content */}
							<NewSideBar />
						</div>
					)}
					<div className={`flex-1 z-0 min-h-screen bg-gray-50 lg:ml-72 md:10`}>
						<div className="bg-gray-50 h-full">
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/dashboard" element={<Dashboard />} />
								<Route path="/farmers" element={<Farmers />} />
								<Route path="/farmers-list" element={<NewFarmersList />} />
								<Route path="/mapview" element={<FarmersMap />} />
								<Route path="/purchases" element={<Purchases />} />
								<Route path="/login" element={<LoginPage />} />
								<Route path="/complete-signup" element={<SignupRedirect />} />
								<Route
									path="/account/validate-account"
									element={<EmailRedirectToSignin />}
								/>
								<Route
									path="/forgotpassword"
									element={<ForgotPasswordPage />}
								/>
								<Route path="/register" element={<RegisterPage />} />
								<Route
									path="/organizations/register"
									element={<OrgRegisterPage />}
								/>
								<Route path="/farmer/:id" element={<FarmerDetailsPage />} />
								<Route path="/employees" element={<Employees />} />
								<Route path="/employee-profile" element={<Profile />} />
								<Route path="/invitations" element={<Invitations />} />
								<Route path="/confirm-invite" element={<Confirmation />} />
								<Route path="/add-new-farmer" element={<AddNewFarmer />} />
								<Route path="/add-new-farm" element={<AddNewFarm />} />
								<Route path="/farmer-profile" element={<FarmerProfile />} />
								<Route path="/admin-account" element={<AdminAccount />} />
								<Route path="/employee-account" element={<EmployeeAccount />} />
								<Route
									path="/add-certification"
									element={<AddCetifications />}
								/>
								<Route path="/add-polygon" element={<AddPolygon />} />

								{/* Catch-all route for undefined pathss */}
								<Route path="*" element={<NotFoundPage />} />
							</Routes>
						</div>
					</div>
				</div>
			</BrowserRouter>
		</div>
	);
}

export default App;
