import React, { useState, useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import {
	GoogleMap,
	useLoadScript,
	DrawingManager,
	Marker,
	Polygon,
	MarkerF,
} from "@react-google-maps/api";
import { REACT_APP_GOOGLE_MAPS_KEY } from "../constants/constants";
import { CircularProgress } from "@mui/material"; // Import a spinner

// Define the libraries array outside of the component
const libraries = ["drawing"];

export default function MapModal({ showModal, setShowModal }) {
	const { polygonCoords, setPolygonCoords } = useStateContext();
	const { markerCoords, setMarkerCoords } = useStateContext();
	const [mapKey, setMapKey] = useState(Date.now());
	const [map, setMap] = useState(null);
	const [selectedShape, setSelectedShape] = useState(null);
	const [center, setCenter] = useState({ lat: 0.31, lng: 32.5728 });
	const [zoom, setZoom] = useState(20);
	const [tempCord, setTempCord] = useState([]);
	const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 });

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
		libraries: libraries, // Use the constant here
	});

	const polyOptions = {
		strokeWeight: 2,
		fillOpacity: 0.45,
		strokeColor: "#FF0000",
		strokeOpacity: 0.8,

		fillColor: "#ffff00",
	};

	const containerStyle = {
		width: "100%",
		height: "100%",
	};

	const getCurrentSpot = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					const newCenter = { lat: latitude, lng: longitude };
					setCurrentPosition(newCenter); // Set the map's center to the current position
				},
				(error) => {
					console.error("Error getting location:", error);
				},
				{
					enableHighAccuracy: true, // Use high accuracy for better results
					timeout: 5000,
					maximumAge: 0,
				},
			);
		} else {
			console.error("Geolocation is not supported by this browser.");
		}
	};

	const handleGetCurrentSpot = () => {
		getCurrentSpot();
	};

	const onLoad = (mapInstance) => {
		setMap(mapInstance);
		if (polygonCoords.length > 0) {
			// Fit the map to the bounds of the polygon on the first load only
			const bounds = new window.google.maps.LatLngBounds();
			polygonCoords.forEach((point) => bounds.extend(point));
			mapInstance.fitBounds(bounds);
		}
	};

	const handlePolygonComplete = (polygon) => {
		if (polygon) {
			clearSelection();
			setSelection(polygon);
			handlePolygonPoints(polygon);
		}
	};

	const handlePolygonPoints = (polygon) => {
		const path = polygon.getPath().getArray();
		const coordinates = path.map((point) => {
			return { lat: point.lat(), lng: point.lng() };
		});
		setTempCord(coordinates);
	};

	const handleClearPolygons = () => {
		setPolygonCoords([]);
		if (selectedShape) {
			selectedShape.setMap(null); // Remove the currently selected shape from the map
			setSelectedShape(null);
		}
	};

	const setSelection = (shape) => {
		if (!shape) return;

		clearSelection();
		setSelectedShape(shape);
		shape.setEditable(true);
		shape.setOptions({ strokeColor: "#00FF00" });

		// Attach event listeners to the polygon's path
		const path = shape.getPath();
		path.addListener("set_at", () => handlePolygonPoints(shape));
		path.addListener("insert_at", () => handlePolygonPoints(shape));
		path.addListener("remove_at", () => handlePolygonPoints(shape));
	};

	const clearSelection = () => {
		if (selectedShape) {
			selectedShape.setEditable(false);
			selectedShape.setOptions({ strokeColor: "#FF0000" });
			setSelectedShape(null);
		}
	};

	const onPolygonClick = (polygon) => {
		clearSelection(); // Ensure only one polygon can be selected at a time
		setSelection(polygon);
	};

	const handleMarkerComplete = (marker) => {
		if (marker) {
			setMarkerCoords([marker.getPosition().toJSON()]);
		}
	};

	const handleClearMarkers = () => {
		setMarkerCoords([]);
	};

	const onCenterChanged = () => {
		if (map) {
			const newCenter = map.getCenter().toJSON();
			if (newCenter.lat !== center.lat || newCenter.lng !== center.lng) {
				setCenter(newCenter);
			}
		}
	};

	const onZoomChanged = () => {
		if (map) {
			const newZoom = map.getZoom();
			if (newZoom !== zoom) {
				setZoom(newZoom);
			}
		}
	};

	const handleModalClose = () => {
		setShowModal(false);
		setPolygonCoords(tempCord);
		setMapKey(Date.now());
	};

	useEffect(() => {
		if (showModal) {
			setMapKey(Date.now());
		}
	}, [showModal]);

	useEffect(() => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setCenter({ lat: latitude, lng: longitude });
				},
				(error) => {
					console.error("Error getting location: ", error);
					setCenter({ lat: 0.31, lng: 32.5728 });
				},
			);
		} else {
			setCenter({ lat: 0.31, lng: 32.5728 });
		}
	}, []);

	if (loadError) {
		return <div>Error loading maps</div>;
	}

	return (
		<>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full">
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
								<div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
									<h3 className="text-3xl font-semibold">Map</h3>
									<button
										className="ml-auto bg-transparent border-0 text-black float-right"
										onClick={handleModalClose}
									>
										<span className="text-black opacity-7 h-6 w-6 text-xl block bg-gray-400 rounded-full">
											×
										</span>
									</button>
								</div>
								<div
									className="relative p-6 flex-auto"
									style={{ height: "calc(100% - 126px)" }}
								>
									{isLoaded && center ? (
										<GoogleMap
											id="map"
											mapContainerStyle={containerStyle}
											zoom={zoom}
											center={center}
											onLoad={onLoad}
											// onCenterChanged={onCenterChanged}
											onZoomChanged={onZoomChanged}
											mapTypeId={window.google.maps.MapTypeId.HYBRID}
										>
											{polygonCoords.length > 0 && (
												<Polygon
													paths={polygonCoords}
													options={polyOptions}
													editable
													onClick={() => onPolygonClick(selectedShape)}
													onMouseUp={() => handlePolygonComplete(selectedShape)}
												/>
											)}

											<Marker
												position={currentPosition}
												icon={{
													url: "./images/red-dot.png",
													scaledSize: new window.google.maps.Size(25, 25),
												}}
											/>

											{markerCoords.map((coord, index) => (
												<MarkerF
													key={index}
													position={coord}
													onDragEnd={(event) => {
														const newPosition = {
															lat: event.latLng.lat(),
															lng: event.latLng.lng(),
														};
														setMarkerCoords([newPosition]);
														console.warn(
															"Marker position updated:",
															newPosition,
														);
													}}
													// icon="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
													icon={"./images/green-dot.png"}
													draggable
												/>
											))}

											<DrawingManager
												onPolygonComplete={handlePolygonComplete}
												onMarkerComplete={(marker) => {
													handleMarkerComplete(marker);
													marker.setMap(null);
												}}
												options={{
													drawingMode: null,
													drawingControlOptions: {
														position:
															window.google.maps.ControlPosition.TOP_CENTER,
														drawingModes: ["polygon", "marker"],
													},
													polygonOptions: polyOptions,
													markerOptions: {
														draggable: true,
														icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png", // Customize the marker
													},
												}}
											/>
										</GoogleMap>
									) : (
										<div className="flex justify-center items-center h-full">
											<CircularProgress /> {/* Spinner while loading */}
										</div>
									)}
								</div>
								<div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
									<button
										className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={handleModalClose}
									>
										Save
									</button>
									<button
										className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={handleGetCurrentSpot}
									>
										Get current position
									</button>
									<button
										className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={handleClearPolygons}
									>
										Clear Polygons
									</button>
									<button
										className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-
150"
										type="button"
										onClick={handleClearMarkers}
									>
										Clear Markers
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}
