import React, { useEffect, useState } from "react";
import NewSideBar from "../components/NewSideBar";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFarmers } from "../actions/farmers";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Loading = () => {
	return <p class="p-2 text-xl ml-2 text-sky-800">Loading.....</p>;
};

const FetchEmployeesError = () => {
	return (
		<p class="p-2 text-red-600 text-xl ml-2">
			Error! Please, check your internet connection
		</p>
	);
};

function Farmers() {
	const [isLoading, setIsLoading] = useState(true);
	const [isDataLoaded, setIsdataLoaded] = useState(false);
	const [isError, setIsError] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [currentSex, setCurrentSex] = useState("All");

	const dispatch = useDispatch();

	const farmers = useSelector((state) => state.farmers);

	useEffect(() => {
		try {
			dispatch(getFarmers(setIsLoading, setIsError, setIsdataLoaded));
		} catch (error) {
			console.log(error);
		}
	}, [isDataLoaded, dispatch]);

	useEffect(() => {
		if (currentSex === "Male") {
			const filterMale = farmers.filter((farmer) => farmer.sex === "Male");
			setFiltered(filterMale);
			// console.log(filterMale);
		} else if (currentSex === "Female") {
			const filterFemale = farmers.filter((farmer) => farmer.sex === "Female");
			setFiltered(filterFemale);
			// console.log(filterFemale);
		} else {
			setFiltered(farmers);
		}
	}, [currentSex, farmers]);

	const farmerTableBody = () => {
		return (
			<tbody>
				{filtered?.map((person, personIdx) => (
					<tr key={person.email}>
						<td
							className={classNames(
								personIdx !== farmers.length - 1
									? "border-b border-gray-200"
									: "",
								"whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-6 lg:pl-8",
							)}
						>
							<Link
								to="/farmer-profile"
								state={{
									person: { ...filtered[personIdx] },
									personIndex: personIdx,
								}}
							>
								<div class="flex">
									{`${person.first_name} ${person.last_name}`}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 22 26"
										strokeWidth={1.5}
										stroke="currentColor"
										className="size-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
										/>
									</svg>
								</div>
							</Link>
						</td>

						<td
							className={classNames(
								personIdx !== filtered.length - 1
									? "border-b border-gray-200"
									: "",
								"hidden whitespace-nowrap px-3 py-4 text-sm text-gray-700 lg:table-cell",
							)}
						>
							{person.phone_number}
						</td>
						<td
							className={classNames(
								personIdx !== filtered.length - 1
									? "border-b border-gray-200"
									: "",
								"whitespace-nowrap px-3 py-4 text-sm text-gray-700",
							)}
						>
							{person.district}
						</td>
						<td
							className={classNames(
								personIdx !== filtered.length - 1
									? "border-b border-gray-200"
									: "",
								"whitespace-nowrap px-3 py-4 text-sm text-gray-700",
							)}
						>
							{person.sex}
						</td>
					</tr>
				))}
			</tbody>
		);
	};

	const farmerTableStates = () => {
		if (isLoading === true) {
			return <Loading />;
		} else if (isLoading === false && isError === true) {
			return <FetchEmployeesError />;
		} else if (
			isLoading === false &&
			isError === false &&
			filtered.length < 1
		) {
			return (
				<p class="p-2 ml-2">No farmers registered under this organzation</p>
			);
		} else if (
			isLoading === false &&
			isError === false &&
			filtered.length > 0
		) {
			return farmerTableBody();
		}
	};

	return (
		<>
			<div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
				<div class="pt-4 pl-4 mt-3 pb-4">
					<p class="font-bold text-3xl pl-2 text-sky-800">Farmers</p>
				</div>
				<div>
					<div className="px-4 sm:px-6 lg:px-8">
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h1 className="text-base font-semibold leading-6 text-sky-800">
									A list of all farmers registered under your company account.
								</h1>
							</div>

							<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex">
								<p className="mr-2 mt-2">Filter</p>
								<select
									value={currentSex}
									onChange={(e) => setCurrentSex(e.target.value)}
									class="p-1 pl-2 pr-2 bg-indigo-600 text-white rounded-md"
								>
									<option>All</option>
									<option>Male</option>
									<option>Female</option>
								</select>
							</div>
							<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
								<Link to="/add-new-farmer">
									<button
										type="button"
										className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Add farmer
									</button>
								</Link>
							</div>
							{/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
								<select
									value={currentSex}
									onChange={(e) => setCurrentSex(e.target.value)}
								>
									<option>All</option>
									<option>Arabica</option>
									<option>Robusta</option>
								</select>
							</div> */}
						</div>
						<div className="mt-8 flow-root">
							<div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
								<div className="inline-block min-w-full py-2 align-middle">
									<table className="min-w-full border-separate border-spacing-0">
										<thead>
											<tr>
												<th
													scope="col"
													className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
												>
													Name
												</th>

												<th
													scope="col"
													className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter lg:table-cell"
												>
													Phone number
												</th>
												<th
													scope="col"
													className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter"
												>
													District
												</th>
												<th
													scope="col"
													className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter"
												>
													Sex
												</th>
											</tr>
										</thead>
										{farmerTableStates()}
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Farmers;
