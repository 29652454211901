// Function to convert degrees to radians
const toRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};

// Function to calculate the distance between two lat/lng points using the Haversine formula
const haversineDistance = (coord1, coord2) => {
  const R = 6371e3; // Earth's radius in meters
  const lat1 = toRadians(coord1.lat);
  const lat2 = toRadians(coord2.lat);
  const deltaLat = toRadians(coord2.lat - coord1.lat);
  const deltaLng = toRadians(coord2.lng - coord1.lng);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in meters
};

// Function to calculate the area of a polygon using the Shoelace formula
const polygonArea = (coords) => {
  const numCoords = coords.length;
  let area = 0;

  for (let i = 0; i < numCoords; i++) {
    const j = (i + 1) % numCoords;
    const xi = coords[i].lat;
    const yi = coords[i].lng;
    const xj = coords[j].lat;
    const yj = coords[j].lng;
    area += xi * yj;
    area -= yi * xj;
  }

  return Math.abs(area / 2.0);
};

// Function to calculate the area of the polygon in square meters
export default function calculateLandArea(coords) {
  // Convert lat/lng coordinates to Cartesian coordinates (meters)
  const coordsInMeters = coords.map((coord) => {
    return {
      lat: haversineDistance({ lat: 0, lng: 0 }, { lat: coord.lat, lng: 0 }), // Y coordinate
      lng: haversineDistance(
        { lat: coord.lat, lng: 0 },
        { lat: coord.lat, lng: coord.lng }
      ), // X coordinate
    };
  });

  // Calculate area using the Shoelace formula
  return polygonArea(coordsInMeters);
}
