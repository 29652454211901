import React, { useEffect, useState } from "react";
import ProfileMap from "../components/ProfileMap";
import { useLocation } from "react-router-dom";
import ProfileAvatar from "../components/ProfileAvatar";
import { useNavigate } from "react-router-dom";
import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/alert";
import { getCertificates, updateFarmer } from "../api/index";
import FailureAlert from "../components/FailureAlert";
import SuccessAlert from "../components/SuccessAlert";

const ConfirmDeletingFarm = ({
	setConfirmDeleteFarmModal,
	handleDeleteFarm,
	currentFarmId,
}) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="error"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="red"
					>
						<AlertIcon boxSize="40px" mr={0} marginTop="25px" />
						<AlertDescription
							maxWidth="sm"
							fontSize="lg"
							marginBottom="13px"
							marginTop="10px"
						>
							Are you sure you want to delete this farm?
						</AlertDescription>
						<div class="flex">
							<button
								class="block rounded-md mr-3 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
								onClick={() => setConfirmDeleteFarmModal(false)}
							>
								Cancel
							</button>
							<button
								class="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 m-auto mb-3"
								onClick={() => handleDeleteFarm(currentFarmId)}
							>
								Yes
							</button>
						</div>
					</Alert>
				</div>
			</div>
		</div>
	);
};
const ConfirmUpdate = ({ setConfirmUpdateModal, handleFarmerUpdate }) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="error"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="blue"
					>
						<AlertIcon boxSize="40px" mr={0} marginTop="25px" />
						<AlertDescription
							maxWidth="sm"
							fontSize="lg"
							marginBottom="13px"
							marginTop="10px"
						>
							Are you sure you want to update this Farmer data?
						</AlertDescription>
						<div class="flex">
							<button
								class="block rounded-md mr-3 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
								onClick={() => setConfirmUpdateModal(false)}
							>
								Cancel
							</button>
							<button
								class="block rounded-md mr-3 bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 m-auto mb-3"
								onClick={() => handleFarmerUpdate()}
							>
								Yes
							</button>
						</div>
					</Alert>
				</div>
			</div>
		</div>
	);
};
function FarmerProfile() {
	const location = useLocation();
	const { person, personIndex } = location.state || [];
	const [farmerDetails, setFarmerDetails] = useState({});
	const [farms, setFarms] = useState([]);
	const [certificates, setCertificates] = useState([]);
	const [showFarm, setShowFarm] = useState(false);
	const [confirmDeleteFarmModal, setConfirmDeleteFarmModal] = useState(false);
	const [currentFarmId, setCurrentFarmId] = useState(null);
	const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
	const [farmID, setFarmID] = useState(null);
	const [deleteBtnState, setDeleteBtnState] = useState(false);
	const [updateBtnState, setUpdateBtnState] = useState(false);
	const [successAlert, setSuccessAlert] = useState(false);
	const [failureAlert, setFailureAlert] = useState(false);
	const navigate = useNavigate();

	const getCerts = async (farmer_id) => {
		try {
			const { data } = await getCertificates(farmer_id);
			setCertificates(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (person) {
			setFarmerDetails(person);
			setFarms(person.farms);
		}
		if (farmerDetails.farmer_id) {
			getCerts(farmerDetails.farmer_id);
		}
	}, [person, farmerDetails.farmer_id]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFarmerDetails((prev) => {
			return { ...prev, [name]: value };
		});
	};
	const handleFarmId = (farm_id) => {
		setCurrentFarmId(farm_id);
		setConfirmDeleteFarmModal(true);
	};

	const handleDeleteFarm = async (id) => {
		try {
			setDeleteBtnState(true);
			let newFarms = farms.filter((farm) => farm.farm_id !== id);
			setFarms(newFarms);
			setConfirmDeleteFarmModal(false);
			setShowFarm(false);
			setDeleteBtnState(false);
			setSuccessAlert(true);
		} catch (error) {
			console.log(error);
			setDeleteBtnState(false);
			setConfirmDeleteFarmModal(false);
			setFailureAlert(true);
		}
	};
	const handleShowFarm = (farm_id) => {
		setFarmID(farm_id);
		setShowFarm((prev) => !prev);
	};

	const handleFarmerUpdate = async () => {
		try {
			setUpdateBtnState(true);
			await updateFarmer(farmerDetails.farmer_id, farmerDetails);
			setConfirmUpdateModal(false);
			setUpdateBtnState(false);
			setSuccessAlert(true);
		} catch (error) {
			console.log(error);
			setUpdateBtnState(false);
			setConfirmUpdateModal(false);
			setFailureAlert(true);
		}
	};

	return (
		<>
			<div class="relative flex flex-col min-w-0 break-words w-auto mb-6 rounded-lg bg-blueGray-100 border-0">
				<div class="rounded-t bg-white mb-0 px-6 py-6">
					<div class="text-center flex">
						<ProfileAvatar imageUrl={farmerDetails?.image_url} />
						<h6 class="text-blueGray-700 text-3xl pl-5 text-sky-800 font-bold mt-2">
							{`${farmerDetails?.first_name}'s Profile`}
						</h6>
					</div>
				</div>
				<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
					<form>
						<div class="flex flex-wrap mt-4">
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs text-sky-800 font-bold mb-2">
										Farmer Code
									</label>
									<input
										type="text"
										name="first_name"
										value={farmerDetails?.farmer_code}
										disabled={true}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold text-sky-800 mb-2">
										Sex
									</label>
									<input
										type="text"
										name="sex"
										onChange={handleChange}
										value={farmerDetails?.sex}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
										First Name
									</label>
									<input
										type="text"
										name="first_name"
										value={farmerDetails?.first_name}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Last Name
									</label>
									<input
										type="text"
										name="last_name"
										onChange={handleChange}
										value={farmerDetails?.last_name}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
										Telephone
									</label>
									<input
										type="text"
										name="phone_number"
										value={farmerDetails?.phone_number}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Email
									</label>
									<input
										type="text"
										name="email"
										onChange={handleChange}
										value={farmerDetails?.email}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
										Date of Birth
									</label>
									<input
										type="datetime-local"
										name="date_of_birth"
										value={farmerDetails?.date_of_birth}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										National ID Number
									</label>
									<input
										type="text"
										name="national_id"
										onChange={handleChange}
										value={farmerDetails?.national_id}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
										District
									</label>
									<input
										type="text"
										name="district"
										value={farmerDetails?.district}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										County
									</label>
									<input
										type="text"
										name="county"
										onChange={handleChange}
										value={farmerDetails?.county}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
										sub county
									</label>
									<input
										type="text"
										name="sub_county"
										value={farmerDetails?.sub_county}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Parish
									</label>
									<input
										type="text"
										name="parish"
										onChange={handleChange}
										value={farmerDetails?.parish}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Village
									</label>
									<input
										type="text"
										name="village"
										onChange={handleChange}
										value={farmerDetails?.village}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Household Members
									</label>
									<input
										type="text"
										name="household_members"
										onChange={handleChange}
										value={farmerDetails?.household_members}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Total Household Income
									</label>
									<input
										type="text"
										name="total_household_income"
										onChange={handleChange}
										value={farmerDetails?.total_household_income}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										School Age Household Members
									</label>
									<input
										type="text"
										name="school_age_household_members"
										onChange={handleChange}
										value={farmerDetails?.school_age_household_members}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Hazardous Prohibition
									</label>
									<input
										type="text"
										name="hazardous_prohibition"
										onChange={handleChange}
										value={farmerDetails?.hazardous_prohibition}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Hunger Days
									</label>
									<input
										type="text"
										name="hunger"
										onChange={handleChange}
										value={farmerDetails?.hunger}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Date of Registration
									</label>
									<input
										type="date"
										name="date_of_registration"
										onChange={handleChange}
										value={farmerDetails?.date_of_registration}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
						</div>
					</form>

					<div class="flex pt-3">
						<button
							class="bg-blue-500 hover:bg-blue-700 text-white ml-4 py-2 px-4 mb-8"
							onClick={() => setConfirmUpdateModal(true)}
							disabled={updateBtnState}
						>
							Save Changes
						</button>
					</div>
				</div>
				<div class="rounded-t bg-white mb-0 px-6 py-6">
					<div class="text-center flex justify-between">
						<h6 class="text-blueGray-700 text-2xl pl-5 text-sky-800 font-bold">
							Farm Details
						</h6>

						<button
							class="bg-blue-500 hover:bg-blue-700 text-white px-4"
							onClick={() =>
								navigate("/add-new-farm", { state: farmerDetails?.farmer_id })
							}
						>
							Add New Farm
						</button>
					</div>
				</div>
				<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
					<div>
						{farms?.map((farm) => (
							<div class="flex flex-wrap ">
								<div class="w-full lg:w-6/12 px-4">
									<div class="relative w-full mb-3 mt-4">
										<label class="block uppercase text-blueGray-600 text-xs font-bold text-sky-800 mb-2">
											Farm Name
										</label>
										<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
											{farm?.farm_name}
										</p>
									</div>
								</div>
								<div class="w-full lg:w-6/12 px-4">
									<div class="relative w-full mb-3 mt-4">
										<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
											Ownership
										</label>
										<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
											{farm?.ownership}
										</p>
									</div>
								</div>
								<div class="w-full lg:w-6/12 px-4">
									<div class="relative w-full mb-3">
										<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
											Land use
										</label>
										<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
											{farm?.land_use}
										</p>
									</div>
								</div>
								<div class="w-full lg:w-6/12 px-4">
									<div class="relative w-full mb-3">
										<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
											Size in Square Meters
										</label>
										<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
											{farm?.size}
										</p>
									</div>
								</div>
								<div class="w-full lg:w-6/12 px-4">
									<div class="relative w-full mb-3">
										<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
											Elevation in Meters
										</label>
										<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
											{farm?.elevation}
										</p>
									</div>
								</div>
								<div class="w-full lg:w-6/12 px-4">
									<div class="relative w-full mb-3">
										<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
											Latitude
										</label>
										<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
											{farm?.point_coordinate.lat}
										</p>
									</div>
								</div>
								<div class="w-full lg:w-6/12 px-4">
									<div class="relative w-full mb-3">
										<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
											Longitude
										</label>
										<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
											{farm?.point_coordinate.lng}
										</p>
									</div>
								</div>

								{farm?.crops[0] ? (
									<div class="flex flex-wrap w-full bg-white mt-3">
										<div class=" lg:w-3/12 px-4 mt-4">
											<div class="relative w-full mb-3">
												<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
													First Crop
												</label>
												<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
													{farm?.crops[0].crop_name}
												</p>
											</div>
										</div>
										<div class="w-full lg:w-3/12 px-4">
											<div class="relative w-full mb-3 mt-4">
												<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
													First Crop Initial Planting Date
												</label>
												<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
													{farm?.crops[0].initial_planting_date.split("T")[0]}
												</p>
											</div>
										</div>
										<div class="w-full lg:w-3/12 px-4">
											<div class="relative w-full mb-3 mt-4">
												<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
													First Crop Recent Planting Date
												</label>
												<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
													{farm?.crops[0].recent_planting_date.split("T")[0]}
												</p>
											</div>
										</div>
										<div class="w-full lg:w-3/12 px-4">
											<div class="relative w-full mb-3 mt-4">
												<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
													First Crop Variety
												</label>
												<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
													{farm?.crops[0].crop_variety}
												</p>
											</div>
										</div>
									</div>
								) : (
									""
								)}
								{farm?.crops[1] ? (
									<div class="flex flex-wrap w-full bg-white">
										<div class=" lg:w-3/12 px-4">
											<div class="relative w-full mb-3 mt-4">
												<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
													Second Crop
												</label>
												<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
													{farm?.crops[1].crop_name}
												</p>
											</div>
										</div>
										<div class="w-full lg:w-3/12 px-4">
											<div class="relative w-full mb-3 mt-4">
												<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
													Second Crop Initial Planting Date
												</label>
												<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
													{farm?.crops[1].initial_planting_date.split("T")[0]}
												</p>
											</div>
										</div>
										<div class="w-full lg:w-3/12 px-4">
											<div class="relative w-full mb-3 mt-4">
												<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
													Second Crop Recent Planting Date
												</label>
												<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
													{farm?.crops[1].recent_planting_date.split("T")[0]}
												</p>
											</div>
										</div>
										<div class="w-full lg:w-3/12 px-4">
											<div class="relative w-full mb-3 mt-4">
												<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
													Second Crop Crop Variety
												</label>
												<p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
													{farm?.crops[1].crop_variety}
												</p>
											</div>
										</div>
									</div>
								) : (
									""
								)}
								<button
									class="w-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mb-5 mr-8"
									onClick={() => handleShowFarm(farm.farm_id)}
								>
									{showFarm ? "HIDE FARM" : "SHOW FARM"}
								</button>
								{showFarm && farm.farm_id === farmID ? (
									<ProfileMap
										pointCords={farm.point_coordinate}
										polygonCoords={farm.polygon_coordinate.coordinate}
										ifSelected={showFarm && farm.farm_id === farmID}
									/>
								) : (
									""
								)}
								{showFarm && farm.farm_id === farmID ? (
									<button
										class="w-full bg-red-500 hover:bg-red-700 text-white py-2 mt-2 px-4 mb-5 mr-8"
										onClick={() => handleFarmId(farm?.farm_id)}
										disabled={deleteBtnState}
									>
										DELETE FARM
									</button>
								) : (
									""
								)}
							</div>
						))}
					</div>
				</div>
				<div class="rounded-t bg-white mb-0 px-6 py-6">
					<div class="text-center flex justify-between">
						<h6 class="text-blueGray-700 text-2xl pl-5 text-sky-800 font-bold">
							Certifications
						</h6>

						<button
							class="bg-blue-500 hover:bg-blue-700 text-white px-4"
							onClick={() =>
								navigate("/add-certification", {
									state: farmerDetails.farmer_id,
								})
							}
						>
							Add New Certificate
						</button>
					</div>
				</div>
				<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
					<div>
						{certificates?.map((cert, index) => {
							return (
								<div
									key={index}
									className="flex justify-between px-4 lg:px-10 py-4 border-0 mt-3 text-blueGray-600 bg-white rounded text-sm shadow ease-linear transition-all duration-150"
								>
									<div className="">
										<p>{cert.certificate_details}</p>
									</div>
									<div className="">
										<button class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4">
											View
										</button>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{confirmDeleteFarmModal ? (
				<ConfirmDeletingFarm
					setConfirmDeleteFarmModal={setConfirmDeleteFarmModal}
					handleDeleteFarm={handleDeleteFarm}
					currentFarmId={currentFarmId}
				/>
			) : (
				""
			)}
			{confirmUpdateModal ? (
				<ConfirmUpdate
					setConfirmUpdateModal={setConfirmUpdateModal}
					handleFarmerUpdate={handleFarmerUpdate}
				/>
			) : (
				""
			)}
			{successAlert ? <SuccessAlert setSuccessAlert={setSuccessAlert} /> : ""}
			{failureAlert ? <FailureAlert setFailureAlert={setFailureAlert} /> : ""}
		</>
	);
}

export default FarmerProfile;
