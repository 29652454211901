import React, { useEffect, useState, useCallback } from "react";
import NewSideBar from "../components/NewSideBar";
import InviteModal from "../components/InviteModal";
import { Link } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { viewCompanyEmployees } from "../api";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Loading = () => {
	return <p class="p-2 text-2xl ml-2">Loading.....</p>;
};

const FetchEmployeesError = () => {
	return (
		<p class="p-2 text-red-600 text-xl ml-2">
			Error! Please, check your internet connection
		</p>
	);
};

export default function Employees() {
	const { companyEmployees, setCompanyEmployees } = useStateContext();
	const fetchUsers = useCallback(async () => {
		try {
			const response = await viewCompanyEmployees();
			setCompanyEmployees(response.data.users);
			setIsLoading(false);
		} catch (error) {
			setIsError(true);
			setIsLoading(false);
		}
	}, [setCompanyEmployees]);

	useEffect(() => {
		fetchUsers();
	}, [fetchUsers]);

	const [inviteModal, showInviteModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const authData = localStorage.getItem("profile");
	// console.log("authData", authData);

	const employeeTableBody = () => {
		return (
			<tbody>
				{companyEmployees?.map((person, personIdx) => (
					<tr key={person.email}>
						<td
							className={classNames(
								personIdx !== companyEmployees.length - 1
									? "border-b border-gray-200"
									: "",
								"whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-6 lg:pl-8",
							)}
						>
							<Link
								to="/employee-profile"
								state={{
									person: { ...companyEmployees[personIdx] },
									personIndex: personIdx,
								}}
							>
								<div class="flex">
									{`${person.first_name} ${person.last_name}`}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 22 26"
										strokeWidth={1.5}
										stroke="currentColor"
										className="size-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
										/>
									</svg>
								</div>
							</Link>
						</td>
						<td
							className={classNames(
								personIdx !== companyEmployees.length - 1
									? "border-b border-gray-200"
									: "",
								"hidden whitespace-nowrap px-3 py-4 text-sm text-gray-700 sm:table-cell",
							)}
						>
							{person.email}
						</td>
						<td
							className={classNames(
								personIdx !== companyEmployees.length - 1
									? "border-b border-gray-200"
									: "",
								"hidden whitespace-nowrap px-3 py-4 text-sm text-gray-700 lg:table-cell",
							)}
						>
							{person.phone_number}
						</td>
						<td
							className={classNames(
								personIdx !== companyEmployees.length - 1
									? "border-b border-gray-200"
									: "",
								"whitespace-nowrap px-3 py-4 text-sm text-gray-700",
							)}
						>
							{person.role}
						</td>
						<td
							className={classNames(
								personIdx !== companyEmployees.length - 1
									? "border-b border-gray-200"
									: "",
								"whitespace-nowrap px-3 py-4 text-sm text-gray-500",
							)}
						>
							{person.active === true ? (
								<button class="bg-green-500 p-1 ml-4 text-white rounded-sm">
									Active
								</button>
							) : (
								<button class="bg-red-400 p-1 text-white rounded-sm">
									Deactivated
								</button>
							)}

							<span className="sr-only">, {person.name}</span>
						</td>
					</tr>
				))}
			</tbody>
		);
	};

	const employeTableStates = () => {
		if (isLoading === true && companyEmployees.length < 1) {
			return <Loading />;
		} else if (isLoading === false && isError === true) {
			return <FetchEmployeesError />;
		} else if (
			isLoading === false &&
			isError === false &&
			companyEmployees.length < 1
		) {
			return <p>No user registered under this organzation</p>;
		} else {
			return employeeTableBody();
		}
	};
	return (
		<>
			<div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white left-0 top-0">
				<NewSideBar />
			</div>
			<div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
				<div class="pt-4 pl-4 mt-3 pb-4">
					<p class="font-bold text-3xl pl-2 text-sky-800">Users</p>
				</div>
				<div>
					<div className="px-4 sm:px-6 lg:px-8">
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h1 className="text-base font-semibold leading-6 text-sky-800">
									A list of all the users in your company account.
								</h1>
							</div>
							<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
								<Link to="/invitations">
									<button
										type="button"
										className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Invited Users
									</button>
								</Link>
							</div>
							<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
								<button
									type="button"
									onClick={() => showInviteModal((prev) => true)}
									className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Add user
								</button>
							</div>
						</div>
						<div className="mt-8 flow-root">
							<div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
								<div className="inline-block min-w-full py-2 align-middle">
									<table className="min-w-full border-separate border-spacing-0">
										<thead>
											<tr>
												<th
													scope="col"
													className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
												>
													Name
												</th>
												<th
													scope="col"
													className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter sm:table-cell"
												>
													Email
												</th>
												<th
													scope="col"
													className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter lg:table-cell"
												>
													Phone number
												</th>
												<th
													scope="col"
													className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter"
												>
													Role
												</th>
												<th
													scope="col"
													className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter"
												>
													Status
												</th>
											</tr>
										</thead>
										{employeTableStates()}
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{inviteModal ? <InviteModal showInviteModal={showInviteModal} /> : ""}
		</>
	);
}
