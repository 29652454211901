import React, { useEffect } from 'react'
import Register from '../user/Register'
import { useStateContext } from '../contexts/ContextProvider'

const RegisterPage = () => {
  const { setActiveMenu } = useStateContext();

  useEffect(() => {
    setActiveMenu(false);
  }, [])
  return (
    <div>
        {/* <Register /> */}
    </div>
  )
}

export default RegisterPage