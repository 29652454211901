import React, { useState, useEffect } from "react";

import { customersData, customersGrid, farmersData } from "../data/dummy";
import { Header, Sidebar } from "../components";
import { Link } from "react-router-dom";
import NewFarmerForm from "../components/NewFarmerForm";
import Navbar from "../components/Navbar";
import { useStateContext } from "../contexts/ContextProvider";
import MapComponent from "../components/Map";
import RightMenu from "../components/RightMenu";
import { useDispatch, useSelector } from "react-redux";
import { getMapViewDetails } from "../actions/farmers";
import NewSideBar from "../components/NewSideBar";

const FarmersMap = () => {
	const mapData = useSelector((state) => state.farmers.mapView);
	// console.log(mapData);
	const [isDataLoaded, setIsdataLoaded] = useState(false);
	const [farmData, setFarmData] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getMapViewDetails(setIsdataLoaded));
		const selectedLocations = mapData?.data?.map(
			(farmer) => farmer.point_coordinate,
		);
		setFarmData(selectedLocations);
	}, [isDataLoaded]);

	const {
		activeMenu,
		setSelectedFarmer,
		rightMenuVisibility,
		setRightMenuVisibility,
		open,
		setOpen,
	} = useStateContext();

	const handleMarkerClick = (location) => {
		// console.log(location);

		const farmer = mapData?.data.find(
			(farmer) =>
				farmer.point_coordinate.lat === location.lat &&
				farmer.point_coordinate.lng === location.lng,
		);
		setSelectedFarmer(farmer);
		setRightMenuVisibility(!rightMenuVisibility);
		setOpen(!open);
	};

	return (
		<>
			{activeMenu ? (
				<div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white left-0 top-0">
					<NewSideBar />
				</div>
			) : (
				""
			)}
			{/* <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
				<Navbar />
			</div> */}
			<div
				className={` ${
					rightMenuVisibility ? "w-72" : "w-0"
				} fixed sidebar dark:bg-secondary-dark-bg bg-white right-0 top-0 z-10`}
			>
				<RightMenu />
			</div>

			<div className="m-2 md:m-10 p-1rounded-3xl">
				<div className="flex justify-between">
					<Header category="Page" title="Map" />
				</div>
				{farmData && (
					<div>
						<MapComponent
							selectedLocations={farmData}
							onMarkerClick={handleMarkerClick}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default FarmersMap;
