import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import LandingIntro from '../user/LandingIntro'
import { useStateContext } from '../contexts/ContextProvider';

const EmailRedirectToSignin = () => {
    const { serverUrl } = useStateContext();

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const [buttonShow, setButtonShow] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {

        if (token) {
            fetch(`${serverUrl}/account/validate-account/${token}`)
                .then(response => response.json())
                .then(data => {
                    // console.log(data);
                    navigate('/login');
                })
                .catch(error => {
                    setButtonShow(true)
                });
        } else {
            console.log("Your token has expired");
        }

    }, [])



    return (
        <div className="min-h-screen bg-base-200 flex items-center p-24">
            <div className="card mx-auto w-full max-w-5xl shadow-xl ">
                <div className="grid  md:grid-cols-2 grid-cols-1 bg-base-100 rounded-xl">
                    <div className='p-10'>
                        <LandingIntro />
                    </div>
                    <div className="flex flex-col items-center pt-24 bg-gray-100">
                        {
                            buttonShow
                                ? <button
                                    className="px-4 mt-20 py-2 bg-sky-800 text-white rounded hover:bg-blue-700"
                                    onClick={() => navigate('/login')}
                                >
                                    Resend Email
                                </button>
                                : <p className="text-lg text-center my-10">
                                    Loading ....
                                </p>
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailRedirectToSignin