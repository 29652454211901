import React, { useEffect, useRef, useCallback } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { REACT_APP_GOOGLE_MAPS_KEY } from "../constants/constants";

const MapComponent = ({
	selectedLocation,
	selectedLocations,
	onMarkerClick,
}) => {
	const libraries = ["places"];
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
		libraries,
	});

	const mapRef = useRef(null);
	const markerClusterRef = useRef(null);

	const defaultCenter =
		selectedLocations?.length > 0 ? selectedLocations[0] : selectedLocation;

	const onLoad = useCallback(
		(map) => {
			mapRef.current = map;

			const markers = selectedLocations.map((location) => {
				const marker = new window.google.maps.Marker({
					position: location,
					icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
				});
				marker.addListener("click", () => onMarkerClick(location));
				return marker;
			});

			markerClusterRef.current = new MarkerClusterer({ map, markers });
			// console.log("MarkerClusterer initialized with markers:", markers);
		},
		[selectedLocations, onMarkerClick],
	);

	useEffect(() => {
		if (isLoaded && mapRef.current) {
			const map = mapRef.current;
			const markers = selectedLocations.map(
				(location, i) =>
					new window.google.maps.Marker({
						position: location,
						// Optional: Customize marker appearance
						icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
						onClick: () => onMarkerClick(location),
					}),
			);
			markerClusterRef.current = new MarkerClusterer({ map, markers });
		}
	}, [isLoaded, selectedLocations, onMarkerClick]);

	if (loadError) return <div>Error loading maps</div>;
	if (!isLoaded) return <div>Loading Maps</div>;

	return (
		<GoogleMap
			mapContainerStyle={{
				width: "100%",
				height: "80vh",
			}}
			zoom={6}
			center={defaultCenter}
			onLoad={onLoad}
		/>
	);
};

export default MapComponent;
