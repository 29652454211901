import React, { useEffect, useState } from "react";
import { confirmInvite } from "../api";
import { useLocation, useNavigate } from "react-router-dom";

function Confirmation() {
	const navigate = useNavigate();
	const location = useLocation();
	const token = location.search.split("token=")[1];
	// console.log(token);

	const [countryCode, setCountryCode] = useState("");
	const [remainingNumber, setRemainingNumber] = useState("");
	const [profileData, setProfileData] = useState({
		password: "",
		first_name: "",
		last_name: "",
		phone_number: "",
	});
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isEqual, setIsEqual] = useState(true);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setProfileData((prev) => {
			return { ...prev, [name]: value };
		});
	};
	const countries = [
		{ name: "Kenya", code: "+254" },
		{ name: "Tanzania", code: "+255" },
		{ name: "Rwanda", code: "+250" },
		{ name: "Uganda", code: "+256" },
		{ name: "USA", code: "+1" },
	];

	const handleCountryChange = (e) => {
		const selectedCountry = countries.find(
			(country) => country.name === e.target.value,
		);
		setCountryCode(selectedCountry ? selectedCountry.code : "");
		handleChange({
			target: {
				name: "country",
				value: selectedCountry ? selectedCountry.name : "",
			},
		});
	};
	const handleRemainingNumberChange = (e) => {
		setRemainingNumber(e.target.value);
		handleChange({
			target: {
				name: "phone_number",
				value: `${countryCode}${e.target.value}`,
			},
		});
	};

	const handleSubmit = () => {
		if (profileData.password === confirmPassword) {
			setIsEqual(true);
			// console.log("confirmation", profileData);
			confirmInvite({ ...profileData }, token, navigate);
		} else {
			// console.log(confirmPassword, profileData.password);
			setIsEqual(false);
		}
	};

	return (
		<>
			<div class="relative flex flex-col min-w-0 break-words w-auto mb-6 rounded-lg bg-blueGray-100 border-0">
				<div class="rounded-t bg-white mb-0 px-6 py-6">
					<div class="text-center flex justify-between">
						<h6 class="text-blueGray-700 text-3xl pl-5 text-sky-800 font-bold">
							Please fill in your details
						</h6>
					</div>
				</div>
				<div class="flex-auto px-4 lg:px-10 py-10 pt-0 bg-gray-100">
					<form>
						<div class="flex flex-wrap mt-4">
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
										First Name
									</label>
									<input
										type="text"
										name="first_name"
										value={profileData?.first_name}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Last Name
									</label>
									<input
										type="text"
										name="last_name"
										onChange={handleChange}
										value={profileData?.last_name}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>

							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Enter Password
									</label>
									<input
										type="password"
										name="password"
										placeholder="Enter password"
										onChange={handleChange}
										value={profileData?.password}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Confirm Password
									</label>

									<div class="relative">
										{isEqual ? (
											""
										) : (
											<p class="text-red-600 text-xs">
												Password does not match
											</p>
										)}
										<input
											type="password"
											name=""
											onChange={(e) =>
												setConfirmPassword((prev) => e.target.value)
											}
											value={confirmPassword}
											placeholder="Re-enter password"
											class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										/>
									</div>
								</div>
							</div>

							<div class="w-full lg:w-6/12 px-4">
								<label
									className="mb-2 text-xs font-semibold text-sky-800"
									htmlFor="country-code"
								>
									COUNTRY
								</label>
								<div class="relative">
									<select
										class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="grid-state"
										onChange={handleCountryChange}
									>
										<option value="">Select Country</option>
										{countries.map((country) => (
											<option key={country.code} value={country.name}>
												{country.name}
											</option>
										))}
									</select>
									<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg
											class="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
										</svg>
									</div>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<label
									className="mb-2 text-xs font-semibold text-sky-800"
									htmlFor="phone-number"
								>
									PHONE NUMBER
								</label>
								<div className="flex">
									<input
										className="w-1/4 px-3 py-3 mb-3 text-sm leading-tight text-sky-800 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
										type="text"
										value={countryCode}
										readOnly
									/>
									<input
										className="w-3/4 px-3 py-2 mb-3 text-sm leading-tight text-sky-800 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
										id="phone_number"
										type="text"
										maxLength={9}
										name="phone_number"
										onChange={handleRemainingNumberChange}
										placeholder="Enter the remaining digits"
										value={remainingNumber}
									/>
								</div>
							</div>
						</div>
					</form>
					<div class="flex pt-3">
						<button
							class="bg-blue-500 hover:bg-blue-700 text-white ml-4 py-2 px-4 mb-8"
							onClick={handleSubmit}
						>
							Submit
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default Confirmation;
