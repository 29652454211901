import { useState } from "react";
import { inviteUser } from "../api";
import {
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/alert";

const InvitationSuccess = ({ showInviteModal }) => {
	return (
		<div class="bg-white relative rounded-lg shadow popup-body">
			<Alert
				status="success"
				variant="subtle"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				height="200px"
				color="green"
			>
				<AlertIcon boxSize="40px" mr={0} color="green" marginTop="25px" />
				<AlertDescription maxWidth="sm" fontSize="lg" marginTop="25px">
					User invitation was successful.
				</AlertDescription>
				<button
					class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
					onClick={() => showInviteModal(false)}
				>
					Close
				</button>
			</Alert>
		</div>
	);
};

const InvitationFailed = ({ showInviteModal }) => {
	return (
		<div class="bg-white relative rounded-lg shadow popup-body">
			<Alert
				status="error"
				variant="subtle"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				height="200px"
				color="red"
			>
				<AlertIcon boxSize="40px" mr={0} marginTop="25px" />
				<AlertTitle mt={4} mb={1} fontSize="lg">
					Invitation was not successful
				</AlertTitle>
				<AlertDescription maxWidth="sm">
					Please check your internet connection and try again.
				</AlertDescription>
				<button
					class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
					onClick={() => showInviteModal(false)}
				>
					Close
				</button>
			</Alert>
		</div>
	);
};

const InvitationForm = ({
	handleInviteSubmission,
	showInviteModal,
	inviteEmail,
	setInviteEmail,
	inviteRole,
	setInviteRole,
}) => {
	return (
		<div class="relative bg-white rounded-lg shadow popup-body">
			<button
				type="button"
				onClick={() => showInviteModal((prev) => false)}
				class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
			>
				<svg
					aria-hidden="true"
					class="w-5 h-5"
					fill="#c6c7c7"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
						cliprule="evenodd"
					></path>
				</svg>
				<span class="sr-only">Close popup</span>
			</button>
			<div class="p-5">
				<h3 class="text-2xl mb-0.5 font-medium"> </h3>
				<p class="mb-4 text-sm font-normal text-gray-800"> </p>
				<div class="mb-7 text-center">
					<p class="mb-3 text-2xl font-semibold leading-5 text-slate-900">
						Invite new user
					</p>
					<p class="mt-2 text-sm leading-4 text-slate-600">
						Enter email and role type.
					</p>
				</div>
				<div>
					<form class="w-full">
						<label for="email" class="sr-only">
							Email address
						</label>
						<input
							name="email"
							type="email"
							autocomplete="email"
							required=""
							class="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-black focus:ring-offset-1"
							placeholder="Email Address"
							onChange={(e) => setInviteEmail(e.target.value)}
							value={inviteEmail}
						/>
						<label for="password" class="sr-only">
							Password
						</label>

						<select
							name="role"
							type="password"
							autocomplete="current-password"
							required=""
							class="mt-2 mb-2 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-black focus:ring-offset-1"
							placeholder="Password"
							id="grid-state"
							value={inviteRole}
							onChange={(e) => setInviteRole(e.target.value)}
						>
							<option>Member</option>
							<option>Admin</option>
						</select>
					</form>
					<button
						onClick={handleInviteSubmission}
						class="inline-flex mb-2 w-full items-center justify-center rounded-lg bg-indigo-600 p-2 py-3 text-sm font-medium text-white outline-none focus:ring-2 focus:ring-black focus:ring-offset-1 disabled:bg-gray-400"
					>
						Invite
					</button>
				</div>
			</div>
		</div>
	);
};

const InviteModal = ({ showInviteModal }) => {
	const [inviteEmail, setInviteEmail] = useState("");
	const [inviteRole, setInviteRole] = useState("Member");
	const [invitationSuccessfulAlert, setInvitationSuccessfulAlert] =
		useState(false);
	const [invitationFailedlAlert, setInvitationFailedAlert] = useState(false);
	const [invitationForm, setInvitationForm] = useState(true);

	const handleInviteSubmission = async () => {
		try {
			// console.log("hey", { email: inviteEmail, role: inviteRole });
			await inviteUser({ email: inviteEmail, role: inviteRole });
			setInvitationForm(false);
			setInvitationSuccessfulAlert(true);
		} catch (error) {
			console.log(error);
			setInvitationForm(false);
			setInvitationFailedAlert(true);
		}
	};

	const invitationStateComponent = () => {
		if (invitationForm === true) {
			return (
				<InvitationForm
					handleInviteSubmission={handleInviteSubmission}
					showInviteModal={showInviteModal}
					inviteEmail={inviteEmail}
					setInviteEmail={setInviteEmail}
					inviteRole={inviteRole}
					setInviteRole={setInviteRole}
				/>
			);
		} else if (invitationSuccessfulAlert === true) {
			return <InvitationSuccess showInviteModal={showInviteModal} />;
		} else {
			return <InvitationFailed showInviteModal={showInviteModal} />;
		}
	};

	return (
		<>
			<div
				id="login-popup"
				tabindex="-1"
				class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
			>
				<div class="relative p-4 w-full max-w-md h-full md:h-auto">
					{invitationStateComponent()}
				</div>
			</div>
		</>
	);
};

export default InviteModal;
