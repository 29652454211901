import React, { useState, useEffect } from "react";
import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/alert";
import {
	deactivateUser,
	updateUser,
	viewUser,
	viewOrganization,
	updateOrganization,
} from "../api";
import FailureAlert from "./FailureAlert";
import SuccessAlert from "./SuccessAlert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewFarmerGroup, createProcessingFacility } from "../api";

const ConfirmDeactivation = ({
	setConfirmDeactivateModal,
	handleDeactivate,
}) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="error"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="red"
					>
						<AlertIcon boxSize="40px" mr={0} marginTop="25px" />
						<AlertDescription
							maxWidth="sm"
							fontSize="lg"
							marginBottom="13px"
							marginTop="10px"
						>
							Are you sure you want to deactivate your account?
						</AlertDescription>
						<div class="flex">
							<button
								class="block rounded-md mr-3 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
								onClick={() => setConfirmDeactivateModal(false)}
							>
								Cancel
							</button>
							<button
								class="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 m-auto mb-3"
								onClick={() => handleDeactivate()}
							>
								Yes
							</button>
						</div>
					</Alert>
				</div>
			</div>
		</div>
	);
};

const ConfirmUpdate = ({ setConfirm, updateFunc, message }) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="error"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="blue"
					>
						<AlertIcon boxSize="40px" mr={0} marginTop="25px" />
						<AlertDescription
							maxWidth="sm"
							fontSize="lg"
							marginBottom="13px"
							marginTop="10px"
						>
							{message}
						</AlertDescription>
						<div class="flex">
							<button
								class="block rounded-md mr-3 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
								onClick={() => setConfirm(false)}
							>
								Cancel
							</button>
							<button
								class="block rounded-md mr-3 bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 m-auto mb-3"
								onClick={() => updateFunc()}
							>
								Yes
							</button>
						</div>
					</Alert>
				</div>
			</div>
		</div>
	);
};
function AdminAccount() {
	const [profileData, setProfileData] = useState({});
	const [organizationData, setOrganizationData] = useState({});
	const [failureAlert, setFailureAlert] = useState(false);
	const [confirmDeactivateModal, setConfirmDeactivateModal] = useState(false);
	const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
	const [confirmUpdateOrg, setConfirmUpdateOrg] = useState(false);
	const navigate = useNavigate();
	const [successAlert, setSuccessAlert] = useState(false);
	const [farmerGroupModal, setFarmerGroupModal] = useState(false);
	const [newFarmerGroup, setNewFarmerGroup] = useState({ group_name: "" });
	const [newFarmerGroupDisableBtn, setNewFarmerGroupDisableBtn] =
		useState(false);
	const [processingFacilityModal, setProcessingFacilityModal] = useState(false);
	const [newProcessingFacility, setNewProcessingFacility] = useState({
		facility_name: "",
	});
	const [newProcessingFacilityDisableBtn, setNewProcessingFacilityDisableBtn] =
		useState(false);
	const authData = useSelector((state) => state.auth.authData);
	// console.log("authData", authData);

	const handleDeactivate = async () => {
		let id = profileData.user_id;
		try {
			await deactivateUser(id);
			navigate("/login");
		} catch (error) {
			console.log(error);
			setFailureAlert(true);
		}
	};

	const getUserDetails = async () => {
		try {
			let { data } = await viewUser();
			// console.log("user", data);
			setProfileData(data);
		} catch (error) {
			console.log(error);
		}
	};

	const getOrganizationDetails = async () => {
		try {
			let { data } = await viewOrganization();
			setOrganizationData(data);
		} catch (error) {
			console.log(error);
		}
	};

	const handleUserUpdate = async () => {
		let id = profileData.user_id;
		try {
			await updateUser(id, profileData);
			setConfirmUpdateModal(false);
			setSuccessAlert(true);
		} catch (error) {
			console.log(error);
			setFailureAlert(true);
		}
	};

	const handleOrganizationUpdate = async () => {
		try {
			await updateOrganization(organizationData);
			setConfirmUpdateOrg(false);
			setSuccessAlert(true);
		} catch (error) {
			console.log(error);
			setConfirmUpdateOrg(false);
			setFailureAlert(true);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setProfileData((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const handleOrganization = (e) => {
		const { name, value } = e.target;
		setOrganizationData((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const handleNewFarmerGroup = (e) => {
		const { name, value } = e.target;
		setNewFarmerGroup((prev) => {
			return { [name]: value };
		});
	};

	const handleNewFarmerGroupSubmit = async (e) => {
		try {
			e.preventDefault();
			setNewFarmerGroupDisableBtn(true);
			await createNewFarmerGroup(newFarmerGroup);
			setNewFarmerGroupDisableBtn(false);
			setFarmerGroupModal(false);
			setSuccessAlert(true);
		} catch (error) {
			console.log(error);
			setNewFarmerGroupDisableBtn(false);
			setFarmerGroupModal(false);
			setFailureAlert(true);
		}
	};
	const handleNewProcessingFacility = (e) => {
		const { name, value } = e.target;
		setNewProcessingFacility((prev) => {
			return { [name]: value };
		});
	};
	const handleNewProcessingFacilitySubmit = async (e) => {
		try {
			e.preventDefault();
			setNewProcessingFacilityDisableBtn(true);
			// console.log(newProcessingFacility);
			await createProcessingFacility(newProcessingFacility);
			setNewProcessingFacilityDisableBtn(false);
			setProcessingFacilityModal(false);
			setSuccessAlert(true);
		} catch (error) {
			console.log(error);
			setNewProcessingFacilityDisableBtn(false);
			setProcessingFacilityModal(false);
			setFailureAlert(true);
		}
	};
	useEffect(() => {
		getUserDetails();
		getOrganizationDetails();
	}, []);
	return (
		<>
			<div class="relative flex flex-col min-w-0 break-words w-auto mb-6 rounded-lg bg-blueGray-100 border-0">
				<div class="rounded-t bg-white mb-0 px-6 py-6">
					<div class="text-center flex justify-between">
						<h6 class="text-blueGray-700 text-3xl pl-5 text-sky-800 font-bold">
							My Account
						</h6>
					</div>
				</div>
				<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
					<div class="flex flex-wrap mt-4">
						<div class="w-full lg:w-6/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
									Organization Name
								</label>
								<input
									type="text"
									name="organization_name"
									value={organizationData?.organization_name}
									onChange={handleOrganization}
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								/>
							</div>
						</div>
						<div class="w-full lg:w-6/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
									Country
								</label>
								<input
									type="text"
									name="country"
									onChange={handleOrganization}
									value={organizationData?.country}
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								/>
							</div>
						</div>
						<div class="flex pt-3">
							<button
								class="bg-blue-500 hover:bg-blue-700 text-white ml-4 py-2 px-4 mb-8"
								onClick={() => setConfirmUpdateOrg(true)}
							>
								Update Organization Details
							</button>
							<button
								class="bg-green-700 hover:bg-green-600 text-white ml-4 py-2 px-4 mb-8"
								onClick={() => setFarmerGroupModal(true)}
							>
								Add New Farmer Group
							</button>
							<button
								class="bg-indigo-700 hover:bg-indigo-500 text-white ml-4 py-2 px-4 mb-8"
								onClick={() => setProcessingFacilityModal(true)}
							>
								Add New Processing Facility
							</button>
						</div>
					</div>

					<form>
						<div class="flex flex-wrap mt-4">
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-sky-800 text-xs font-bold mb-2">
										First Name
									</label>
									<input
										type="text"
										name="first_name"
										value={profileData?.first_name}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-sky-800 text-blueGray-600 text-xs font-bold mb-2">
										Last Name
									</label>
									<input
										type="text"
										name="last_name"
										onChange={handleChange}
										value={profileData?.last_name}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>

							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block text-sky-800 uppercase text-blueGray-600 text-xs font-bold mb-2">
										Email
									</label>
									<input
										type="email"
										value={profileData?.email}
										onChange={handleChange}
										name="email"
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>

							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold text-sky-800 mb-2">
										Phone Number
									</label>
									<input
										type="text"
										name="phone_number"
										value={profileData?.phone_number}
										onChange={handleChange}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
						</div>
					</form>
					{confirmDeactivateModal ? (
						<ConfirmDeactivation
							setConfirmDeactivateModal={setConfirmDeactivateModal}
							handleDeactivate={handleDeactivate}
						/>
					) : (
						""
					)}

					{failureAlert ? (
						<FailureAlert setFailureAlert={setFailureAlert} />
					) : (
						""
					)}
					{confirmUpdateModal ? (
						<ConfirmUpdate
							setConfirm={setConfirmUpdateModal}
							updateFunc={handleUserUpdate}
							message="Update user"
						/>
					) : (
						""
					)}
					{confirmUpdateOrg ? (
						<ConfirmUpdate
							setConfirm={setConfirmUpdateOrg}
							updateFunc={handleOrganizationUpdate}
							message="Update Organization"
						/>
					) : (
						""
					)}

					{successAlert ? (
						<SuccessAlert setSuccessAlert={setSuccessAlert} />
					) : (
						""
					)}
					{failureAlert ? (
						<FailureAlert setFailureAlert={setFailureAlert} />
					) : (
						""
					)}
					<div class="flex pt-3">
						<button
							class="bg-red-800 hover:bg-red-500 text-white ml-4 py-2 px-4 mb-8"
							// onClick={() => handleDeactivate(profileData.user_id)}
							onClick={() => setConfirmDeactivateModal(true)}
						>
							Deactivate Account
						</button>

						<button
							class="bg-blue-500 hover:bg-blue-700 text-white ml-4 py-2 px-4 mb-8"
							// onClick={() => handleUserUpdate(profileData.user_id)}
							onClick={() => setConfirmUpdateModal(true)}
						>
							Update Account Details
						</button>
					</div>
				</div>
			</div>
			{farmerGroupModal ? (
				<AddFarmerGroupModal
					newFarmerGroup={newFarmerGroup}
					setNewFarmerGroup={setNewFarmerGroup}
					handleNewFarmerGroup={handleNewFarmerGroup}
					handleNewFarmerGroupSubmit={handleNewFarmerGroupSubmit}
					newFarmerGroupDisableBtn={newFarmerGroupDisableBtn}
					setFarmerGroupModal={setFarmerGroupModal}
				/>
			) : (
				""
			)}
			{processingFacilityModal ? (
				<AddProcessingFacilityModal
					handleNewProcessingFacilitySubmit={handleNewProcessingFacilitySubmit}
					setProcessingFacilityModal={setProcessingFacilityModal}
					newProcessingFacility={newProcessingFacility}
					handleNewProcessingFacility={handleNewProcessingFacility}
					newProcessingFacilityDisableBtn={newProcessingFacilityDisableBtn}
				/>
			) : (
				""
			)}
		</>
	);
}

export default AdminAccount;

const AddFarmerGroupModal = ({
	handleNewFarmerGroupSubmit,
	setFarmerGroupModal,
	newFarmerGroup,
	handleNewFarmerGroup,
	newFarmerGroupDisableBtn,
}) => {
	return (
		<>
			<div
				id="login-popup"
				tabindex="-1"
				class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
			>
				<div class="relative p-4 w-full max-w-md h-full md:h-auto">
					<div class="bg-white relative rounded-lg shadow popup-body">
						<div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
							<h3 className="text-lg text-sky-800 font-semibold">
								Enter Group Name
							</h3>
							<button
								className="ml-auto bg-transparent border-0 text-black float-right"
								onClick={() => setFarmerGroupModal(false)}
							>
								<span className="text-black opacity-7 h-6 w-6 text-xl block bg-gray-400 rounded-full">
									×
								</span>
							</button>
						</div>
						<form onSubmit={handleNewFarmerGroupSubmit}>
							<div className="flex flex-wrap mt-4">
								<div className="w-full lg:w-12/12 px-4">
									<div className="relative w-full mb-3">
										<input
											type="text"
											required
											name="group_name"
											placeholder="Enter farm group name here.."
											value={newFarmerGroup?.group_name}
											onChange={(e) => handleNewFarmerGroup(e)}
											className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										/>
									</div>
								</div>
								<button
									class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 ml-10 w-3/4 mb-8"
									type="submit"
									disabled={newFarmerGroupDisableBtn}
								>
									Save
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

const AddProcessingFacilityModal = ({
	handleNewProcessingFacilitySubmit,
	setProcessingFacilityModal,
	newProcessingFacility,
	handleNewProcessingFacility,
	newProcessingFacilityDisableBtn,
}) => {
	return (
		<>
			<div
				id="login-popup"
				tabindex="-1"
				class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
			>
				<div class="relative p-4 w-full max-w-md h-full md:h-auto">
					<div class="bg-white relative rounded-lg shadow popup-body">
						<div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
							<h3 className="text-lg text-sky-800 font-semibold">
								Enter Processing Facility Name
							</h3>
							<button
								className="ml-auto bg-transparent border-0 text-black float-right"
								onClick={() => setProcessingFacilityModal(false)}
							>
								<span className="text-black opacity-7 h-6 w-6 text-xl block bg-gray-400 rounded-full">
									×
								</span>
							</button>
						</div>
						<form onSubmit={handleNewProcessingFacilitySubmit}>
							<div className="flex flex-wrap mt-4">
								<div className="w-full lg:w-12/12 px-4">
									<div className="relative w-full mb-3">
										<input
											type="text"
											required
											name="facility_name"
											placeholder="Enter processing facility name here.."
											value={newProcessingFacility?.facility_name}
											onChange={(e) => handleNewProcessingFacility(e)}
											className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										/>
									</div>
								</div>
								<button
									class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 ml-10 w-3/4 mb-8"
									type="submit"
									disabled={newProcessingFacilityDisableBtn}
								>
									Save
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};
