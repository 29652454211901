import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LandingIntro from "./LandingIntro";
import ErrorText from "../components/Typography/ErrorText";
import InputText from "../components/inputs/InputText";
import { useDispatch } from "react-redux";
import { signup } from "../actions/auth";
import { useStateContext } from "../contexts/ContextProvider";

const initialState = {
	organization_name: "",
	country: "",
	first_name: "",
	last_name: "",
	email: "",
	phone_number: "",
	password: "",
	confirm_password: "",
};

function Register() {
	const { serverUrl } = useStateContext();
	const [companyLoad, setCompanyLoad] = useState(false);
	const [coExists, setCoExists] = useState(null);

	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState(initialState);
	const [errorMessage, setErrorMessage] = useState("");
	const [countryCode, setCountryCode] = useState("");
	const [remainingNumber, setRemainingNumber] = useState("");
	const [isChecked, setIsChecked] = useState(false);

	const countries = [
		{ name: "Kenya", code: "+254" },
		{ name: "Tanzania", code: "+255" },
		{ name: "Rwanda", code: "+250" },
		{ name: "Uganda", code: "+256" },
		{ name: "USA", code: "+1" },
	];

	const handleCountryChange = (e) => {
		const selectedCountry = countries.find(
			(country) => country.name === e.target.value,
		);
		setCountryCode(selectedCountry ? selectedCountry.code : "");
		handleChange({
			target: {
				name: "country",
				value: selectedCountry ? selectedCountry.name : "",
			},
		});
	};

	const handleRemainingNumberChange = (e) => {
		setRemainingNumber(e.target.value);
		handleChange({
			target: {
				name: "phone_number",
				value: `${countryCode}${e.target.value}`,
			},
		});
	};

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const submitForm = (e) => {
		e.preventDefault();

		const requiredFields = [
			"country",
			"organization_name",
			"first_name",
			"last_name",
			"phone_number",
			"email",
			"password",
			"confirm_password",
		];
		let allFieldsFilled = true;

		requiredFields.forEach((field) => {
			const element = document.getElementById(field);
			if (formData[field] === "") {
				setErrorMessage("You need to fill the missing fields!");
				element?.classList.add("border", "border-red-500");
				allFieldsFilled = false;
			} else {
				element?.classList.remove("border", "border-red-500");
			}
		});

		if (allFieldsFilled) {
			setLoading(true);
			dispatch(signup(formData, navigate));
		}
	};

	const handleChange = (e) => {
		setErrorMessage("");
		setFormData({ ...formData, [e.target.name]: e.target.value });

		// Remove the red border once edited
		const element = document.getElementById(e.target.name);
		if (element) {
			element.classList.remove("border", "border-red-500");
		}
	};

	var companyName = formData.organization_name;
	useEffect(() => {
		if (companyName) {
			setCompanyLoad(true);
			// console.log(companyName);

			fetch(`${serverUrl}/account/check-organization-name/${companyName}`)
				.then((response) => response.json())
				.then((data) => {
					// console.log(data);
					if (data.success) {
						setCoExists("true");
						setCompanyLoad(false);
					} else if (data.failure) {
						setCoExists("failure");
						setCompanyLoad(false);
					} else {
						setCoExists("unknown");
						setCompanyLoad(false);
					}
				})
				.catch((error) => {
					console.error("Error checking company name:", error);
					setCompanyLoad(false);
				});
		} else {
			setCoExists(null);
		}
	}, [companyName]);

	return (
		<div className="min-h-screen lg:-ml-72 bg-base-200 flex items-center">
			<div className="card mx-auto w-full max-w-5xl  shadow-xl">
				<div className="grid bg-white md:grid-cols-2 grid-cols-1 mt-4  bg-base-100 rounded-xl">
					<div className="pt-24">
						<img
							src="./cherio.png"
							alt="cherio-logo"
							className="block ml-auto mr-auto mb-2"
						/>
						<div className="ml-4 hidden lg:block">
							<LandingIntro />
						</div>
					</div>
					<div class="w-full bg-white p-5 rounded-lg lg:rounded-l-none">
						<h3 class="pt-4 text-2xl text-center font-bold text-sky-800">
							Create an Account!
						</h3>
						<div className="text-center">
							<p className="text-sm text-red-600">
								{errorMessage && errorMessage}
							</p>
						</div>
						<form
							class="px-8 pt-6 pb-8 mb-4 bg-white rounded"
							onSubmit={submitForm}
						>
							<div class="mb-2 md:flex md:justify-between">
								<div class="mb-0 md:mr-2 md:mb-0">
									<label
										class="mb-2 text-xs font-semibold text-sky-800"
										for="firstName"
									>
										FIRST NAME
									</label>
									<input
										class="w-full px-3 py-2 text-sm leading-tight text-sky-800 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
										id="first_name"
										name="first_name"
										type="text"
										onChange={handleChange}
										placeholder="First Name"
									/>
								</div>
								<div class="md:ml-2">
									<label
										class="mb-2 text-xs font-semibold text-sky-800"
										for="lastName"
									>
										LAST NAME
									</label>
									<input
										class="w-full px-3 py-2 text-sm leading-tight text-sky-800 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
										id="last_name"
										name="last_name"
										type="text"
										onChange={handleChange}
										placeholder="Last Name"
									/>
								</div>
							</div>
							<div class="mb-2">
								<label
									class="mb-2 text-xs font-semibold text-sky-800"
									for="email"
								>
									EMAIL
								</label>
								<input
									class="w-full px-3 py-2 mb-3 text-sm leading-tight text-sky-800 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
									id="email"
									type="email"
									name="email"
									onChange={handleChange}
									placeholder="Email"
								/>
							</div>

							<div class="mb-2 md:flex md:justify-between">
								<div class="">
									<label
										className="mb-2 text-xs font-semibold text-sky-800"
										htmlFor="country-code"
									>
										COUNTRY
									</label>
									<select
										className="w-full px-3 py-2 mb-3 text-sm leading-tight text-sky-800 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
										id="country-code country"
										onChange={handleCountryChange}
									>
										<option value="">Select Country</option>
										{countries.map((country) => (
											<option key={country.code} value={country.name}>
												{country.name}
											</option>
										))}
									</select>
								</div>

								<div class="md:ml-2">
									<label
										className="mb-2 text-xs font-semibold text-sky-800"
										htmlFor="phone-number"
									>
										PHONE NUMBER
									</label>
									<div className="flex">
										<input
											className="w-1/4 px-3 py-2 mb-3 text-sm leading-tight text-sky-800 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
											type="text"
											value={countryCode}
											readOnly
										/>
										<input
											className="w-3/4 px-3 py-2 mb-3 text-sm leading-tight text-sky-800 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
											id="phone_number"
											type="text"
											maxLength={9}
											name="phone_number"
											onChange={handleRemainingNumberChange}
											placeholder="Enter the remaining digits"
											value={remainingNumber}
										/>
									</div>
								</div>
							</div>

							<div className="mb-2 ">
								<div class="mb-4 md:mr-2 md:mb-0">
									<label
										class="mb-2 text-xs font-semibold text-sky-800"
										for="companyName"
									>
										COMPANY NAME
									</label>
									<input
										class="w-full px-3 py-2 text-sm leading-tight text-sky-800 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
										id="organization_name"
										type="text"
										onChange={handleChange}
										name="organization_name"
										placeholder="Company Name"
									/>
									{companyLoad && (
										<p className="text-xs text-gray-500">Checking...</p>
									)}
									{!companyLoad && coExists !== null && (
										<p
											className={`text-xs ${
												coExists == "failure"
													? "text-red-500"
													: "text-green-500"
											}`}
										>
											{coExists == "failure"
												? "Company name already exists."
												: "Company name is available."}
										</p>
									)}
								</div>
							</div>

							<div class="mb-2 md:flex md:justify-between">
								<div class="mb-4 md:mr-2 md:mb-0">
									<label
										class="mb-2 text-xs font-semibold text-sky-800"
										for="password"
									>
										PASSWORD
									</label>
									<input
										class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
										id="password"
										type="password"
										name="password"
										onChange={handleChange}
										placeholder=""
									/>
									{/* <p class="text-xs italic text-red-500">Please choose a password.</p> */}
								</div>
								<div class="md:ml-2">
									<label
										class="mb-2 text-xs font-semibold text-sky-800"
										for="c_password"
									>
										CONFIRM PASSWORD
									</label>
									<input
										class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
										id="confirm_password"
										type="password"
										onChange={handleChange}
										name="confirm_password"
										placeholder=""
									/>
								</div>
							</div>
							<div className="mb-4 text-center">
								<label
									htmlFor="hs-checkbox-in-form"
									className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
								>
									<input
										type="checkbox"
										className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
										id="hs-checkbox-in-form"
										checked={isChecked}
										onChange={handleCheckboxChange}
									/>
									<span className="text-sm text-sky-800 ms-3 dark:text-gray-400">
										Agree to the terms and conditions
									</span>
								</label>
							</div>
							<div className="mb-6 text-center">
								<button
									className={`w-full px-4 py-2 font-bold text-white ${
										!isChecked ? "bg-blue-500" : "bg-blue-700"
									}  rounded-full  focus:outline-none focus:shadow-outline`}
									type="submit"
									disabled={!isChecked}
								>
									Register Account
								</button>
							</div>
							<hr class="mb-4 border-t" />
							<div class="text-center">
								<a
									class="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800 hover:underline"
									href="/login"
								>
									Already have an account? Login!
								</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Register;
