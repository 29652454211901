import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { createCertificate } from "../actions/farmers";
import { useDispatch } from "react-redux";
import { getFarmsLinkedToFarmer, deleteCertificate } from "../api";
import { useNavigate } from "react-router-dom";
import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/alert";
import { useLocation } from "react-router-dom";
import { getCertificates } from "../api/index";

const ConfirmDeactivation = ({
	setConfirmDeleteCertModal,
	handleDeleteCert,
	currentCertId,
}) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="error"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="red"
					>
						<AlertIcon boxSize="40px" mr={0} marginTop="25px" />
						<AlertDescription
							maxWidth="sm"
							fontSize="lg"
							marginBottom="13px"
							marginTop="10px"
						>
							Are you sure you want to delete this certificate?
						</AlertDescription>
						<div class="flex">
							<button
								class="block rounded-md mr-3 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
								onClick={() => setConfirmDeleteCertModal(false)}
							>
								Cancel
							</button>
							<button
								class="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 m-auto mb-3"
								onClick={() => handleDeleteCert(currentCertId)}
							>
								Yes
							</button>
						</div>
					</Alert>
				</div>
			</div>
		</div>
	);
};

function AddCetifications() {
	const farmerInfo = useSelector((state) => state);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [certDetails, setCertDetails] = useState({
		certificate_name: "",
		certification_date: "",
		farm_id: "",
		certificate_image: null,
		certificate_id_number: "",
	});
	const [farms, setFarms] = useState([]);
	const [selectedFarm, setSelectedFarm] = useState("");
	const [showSaveCertModal, setSaveShowCertModal] = useState(false);
	const [addedCerts, setAddedCerts] = useState([]);
	const [confirmDeleteCertModal, setConfirmDeleteCertModal] = useState(false);
	const [currentCertId, setCurrentCertId] = useState(null);

	const [linkToFarmer, setLinkToFarmer] = useState(false);
	const [disableBtn, setDisableBtn] = useState(false);

	const farmerIdFromNavigation = location?.state;
	let farmerIdFromReducer = farmerInfo.farmers.savedFarmer?.farmer_id;

	let farmer_id;

	if (!farmerIdFromReducer) {
		farmer_id = farmerIdFromNavigation;
	} else {
		farmer_id = farmerIdFromReducer;
	}

	const getCerts = async (farmer_id) => {
		try {
			const { data } = await getCertificates(farmer_id);
			setAddedCerts((prev) => [...prev, ...data.data]);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getCerts(farmer_id);
	}, [farmer_id]);

	const getFarms = useCallback(async () => {
		try {
			// console.log("requesting....", farmer_id);
			const { data } = await getFarmsLinkedToFarmer(farmer_id);
			setFarms(data.farms);
		} catch (error) {}
	}, [farmer_id]);

	useEffect(() => {
		getFarms();
	}, [getFarms]);

	const handleCertDetails = (e) => {
		const { name, value } = e.target;
		setCertDetails((prev) => {
			return { ...prev, [name]: value };
		});
	};
	const handleFileChange = (e) => {
		const file = e.target.files[0];

		const { name } = e.target;
		setCertDetails((prev) => {
			return { ...prev, [name]: file };
		});
	};
	const handleCloseSaveCertModal = () => {
		setSaveShowCertModal(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let certInfo = {
			...certDetails,
			certification_date: `${certDetails.certification_date}T14:00:00`,
			farm_id: linkToFarmer ? "" : selectedFarm,
		};

		dispatch(
			createCertificate(
				certInfo,
				farmer_id,
				setAddedCerts,
				setCertDetails,
				setSaveShowCertModal,
				setDisableBtn,
				navigate,
			),
		);
	};

	const handleCertId = (cert_id) => {
		setCurrentCertId(cert_id);
		setConfirmDeleteCertModal(true);
	};

	const handleDeleteCert = async (cert_id) => {
		try {
			let del = await deleteCertificate(cert_id);
			setAddedCerts((prev) => {
				return prev.filter((cert) => cert.certificate_id !== Number(cert_id));
			});
			setConfirmDeleteCertModal(false);
			// console.log("del", del);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<div className="relative flex flex-col min-w-0 break-words w-full mb-6 mt-10 rounded-lg bg-blueGray-100 border-0">
				<div className="rounded-t bg-white mb-0 px-6 py-6">
					<div className="text-center flex justify-between">
						<h5 className="text-sky-800 text-xl font-bold">
							Upload Certificates
						</h5>
						<button
							onClick={() => setSaveShowCertModal(true)}
							type="button"
							className="block rounded-md bg-sky-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Add Certificate
						</button>
					</div>
				</div>
				<div>
					{addedCerts.map((cert, index) => {
						return (
							<div
								key={index}
								className="flex justify-between px-4 lg:px-10 py-4 border-0 mt-3 text-blueGray-600 bg-white rounded text-sm shadow ease-linear transition-all duration-150"
							>
								<div className="">
									<p>{cert.certificate_details}</p>
								</div>
								<div className="">
									<button
										onClick={() => handleCertId(cert.certificate_id)}
										className="block rounded-md bg-red-600 px-2 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Remove
									</button>
								</div>
							</div>
						);
					})}
					<div class="flex justify-center">
						<button
							onClick={() => navigate("/farmers-list")}
							type="button"
							className="block rounded-md mt-4 ml-1 bg-sky-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Complete
						</button>
					</div>
				</div>
			</div>
			{showSaveCertModal ? (
				<SaveCertificateModal
					certDetails={certDetails}
					handleCertDetails={handleCertDetails}
					farms={farms}
					handleCloseSaveCertModal={handleCloseSaveCertModal}
					handleFileChange={handleFileChange}
					handleSubmit={handleSubmit}
					setCertDetails={setCertDetails}
					selectedFarm={selectedFarm}
					setSelectedFarm={setSelectedFarm}
					linkToFarmer={linkToFarmer}
					setLinkToFarmer={setLinkToFarmer}
					disableBtn={disableBtn}
				/>
			) : (
				""
			)}
			{confirmDeleteCertModal ? (
				<ConfirmDeactivation
					setConfirmDeleteCertModal={setConfirmDeleteCertModal}
					handleDeleteCert={handleDeleteCert}
					currentCertId={currentCertId}
				/>
			) : (
				""
			)}
		</>
	);
}

const SaveCertificateModal = ({
	certDetails,
	handleCertDetails,
	farms,
	handleCloseSaveCertModal,
	handleFileChange,
	handleSubmit,
	setSelectedFarm,
	linkToFarmer,
	setLinkToFarmer,
	disableBtn,
}) => {
	return (
		<>
			<div
				id="login-popup"
				tabindex="-1"
				class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
			>
				<div class="relative p-4 w-full max-w-md h-full md:h-auto">
					<div class="bg-white relative rounded-lg shadow popup-body">
						<div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
							<h3 className="text-lg text-sky-800 font-semibold">
								Please fill in the Details
							</h3>
							<button
								className="ml-auto bg-transparent border-0 text-black float-right"
								onClick={handleCloseSaveCertModal}
							>
								<span className="text-black opacity-7 h-6 w-6 text-xl block bg-gray-400 rounded-full">
									×
								</span>
							</button>
						</div>
						<form onSubmit={handleSubmit}>
							<div className="flex flex-wrap mt-4">
								<div className="w-full lg:w-12/12 px-4">
									<div className="relative w-full mb-3">
										<label className="block text-sky-800 uppercase text-blueGray-600 text-xs font-bold mb-2">
											Certificate Name
										</label>
										<input
											type="text"
											required
											name="certificate_name"
											value={certDetails.certificate_name}
											onChange={handleCertDetails}
											className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										/>
									</div>
								</div>
								<div className="w-full lg:w-12/12 px-4">
									<div className="relative w-full mb-3">
										<label className="block text-sky-800 uppercase text-blueGray-600 text-xs font-bold mb-2">
											Certificate ID Number
										</label>
										<input
											type="text"
											name="certificate_id_number"
											required
											value={certDetails.certificate_id_number}
											onChange={handleCertDetails}
											className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										/>
									</div>
								</div>

								<div className="w-full lg:w-12/12 px-4">
									<div className="relative w-full mb-3">
										<label className="block text-sky-800 uppercase text-blueGray-600 text-xs font-bold mb-2">
											Date of Certification
										</label>
										<input
											type="date"
											name="certification_date"
											required
											onChange={handleCertDetails}
											value={certDetails.certification_date}
											className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										/>
									</div>
								</div>
								<div className="w-full lg:w-12/12 px-4">
									<div className="relative w-full mb-3 mt-3 flex">
										<input
											type="checkbox"
											id={565}
											checked={linkToFarmer}
											onChange={() => setLinkToFarmer((prev) => !prev)}
											disabled={false}
											className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 disabled:opacity-50 disabled:cursor-not-allowed"
										/>
										<label className="block text-sky-800 uppercase text-blueGray-600 text-xs pt-1 font-bold ml-2 mb-2">
											Link to Farmer
										</label>
									</div>
								</div>
								{linkToFarmer ? (
									""
								) : (
									<div className="w-full lg:w-12/12 px-4">
										<div className="relative w-full mb-3">
											<label className="block text-sky-800 uppercase text-blueGray-600 text-xs font-bold mb-2">
												Link to Farm
											</label>
											<div className="relative">
												<select
													className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="grid-state"
													onChange={(e) => setSelectedFarm(e.target.value)}
												>
													<option value="">--Select Variety--</option>
													{farms.map((farm, index) => (
														<option key={farm.id} value={farm.farm_id}>
															{farm.farm_name}
														</option>
													))}
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
									</div>
								)}
								<div className="w-full px-4">
									<div className="relative w-full mb-3">
										<label className="block text-sky-800 uppercase text-blueGray-600 text-xs font-bold mb-2">
											Upload Certificate
										</label>
										<input
											type="file"
											required
											name="certificate_image"
											onChange={handleFileChange}
											className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										/>
									</div>
								</div>
							</div>
							<button
								class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 ml-10 w-3/4 mb-8"
								type="submit"
								disabled={disableBtn}
							>
								Save
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddCetifications;
