import {
	FETCH_FARMER,
	FETCH_FARMERS,
	DELETE_FARMER,
	MAP_VIEW,
	CREATE_FARMER,
	FETCH_GROUPS,
	FETCH_FACILITIES,
} from "../constants/actionTypes";
import * as api from "../api/index";

//Action creators
export const getFarmers =
	(setIsLoading, setIsError, setIsdataLoaded) => async (dispatch) => {
		try {
			const { data } = await api.fetchFarmers();
			dispatch({ type: FETCH_FARMERS, payload: data.data });
			setIsLoading(false);
			setIsdataLoaded(true);
		} catch (error) {
			console.log(error);
			setIsError(true);
		}
	};

export const getGroups = () => async (dispatch) => {
	try {
		const { data } = await api.fetchGroups();
		dispatch({ type: FETCH_GROUPS, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const getFacilities = () => async (dispatch) => {
	try {
		const { data } = await api.fetchFacilities();
		dispatch({ type: FETCH_FACILITIES, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const getFarmer = (id) => async (dispatch) => {
	try {
		const { data } = await api.farmerInfo(id);
		dispatch({ type: FETCH_FARMER, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const createFarm =
	(id, farm, navigate, setSuccessAlert, setFailureAlert, setSubmitBtnState) =>
	async (dispatch) => {
		try {
			const { data } = await api.createFarm(id, farm);
			// console.log(data);
			setSuccessAlert(true);
			navigate("/add-polygon", {
				state: { farm_id: data.farm_id, farmer_id: id },
			});
		} catch (error) {
			console.log(error);
			setSubmitBtnState(false);
			setFailureAlert(true);
		}
	};

export const createFarmer =
	(farmer, setProceedToFarm, setFailureAlert, setButtonState) =>
	async (dispatch) => {
		try {
			const { data } = await api.createFarmer(farmer);
			dispatch({ type: CREATE_FARMER, payload: data });
			setProceedToFarm(true);
		} catch (error) {
			console.log(error);
			setButtonState(false);
			setFailureAlert(true);
		}
	};

export const createCertificate = (
	certInfo,
	farmer_id,
	setAddedCerts,
	setCertDetails,
	setSaveShowCertModal,
	setDisableBtn,
	navigate,
) => {
	return async (dispatch) => {
		try {
			setDisableBtn(true);
			const { data } = await api.postCertification(certInfo, farmer_id);
			setAddedCerts((prev) => [...prev, data]);
			setCertDetails({
				certificate_name: "",
				certification_date: "",
				farm_id: "",
				certificate_image: null,
				certificate_id_number: "",
			});
			setSaveShowCertModal(false);
			setDisableBtn(false);
		} catch (error) {
			console.log(error);
		}
	};
};

export const deleteFarmer = (id) => async (dispatch) => {
	try {
		await api.deleteFarmer(id);

		dispatch({ type: DELETE_FARMER, payload: id });
	} catch (error) {
		console.log(error);
	}
};

export const getMapViewDetails = (setIsdataLoaded) => async (dispatch) => {
	try {
		const { data } = await api.mapView();
		dispatch({ type: MAP_VIEW, payload: data });
		setIsdataLoaded(true);
		setIsdataLoaded(true);
	} catch (error) {
		console.log(error);
	}
};
