import React, { useEffect } from 'react'
import Login from '../user/Login'
import { useStateContext } from '../contexts/ContextProvider'

const LoginPage = () => {
  const { setActiveMenu } = useStateContext();

  useEffect(() => {
    setActiveMenu(false);
  }, [])
  return (
    <div className=''>
        <Login />
    </div>
  )
}

export default LoginPage