import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import {
	ChevronRightIcon,
	Bars3Icon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import {
	HomeIcon,
	Cog8ToothIcon,
	UsersIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function NewSideBar() {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	let user_role;
	if (JSON.parse(localStorage.getItem("profile"))) {
		user_role = JSON.parse(localStorage.getItem("profile")).role;
	}

	const initialNavigation = [
		{ name: "Dashboard", href: "dashboard", icon: HomeIcon, current: true },
		{
			name: "Farmers",
			icon: UsersIcon,
			current: false,
			children: [
				{ name: "Farmers List", href: "farmers-list" },
				{ name: "Map View", href: "mapview" },
				{ name: "Add New Farmer", href: "add-new-farmer" },
			],
		},
		{
			name: "Settings",
			icon: Cog8ToothIcon,
			current: false,
			children: [
				user_role === "Admin" ? { name: "Users", href: "employees" } : "",
				user_role === "Admin"
					? ""
					: { name: "My Account", href: "employee-account" },
				user_role === "Admin"
					? { name: "My Account", href: "admin-account" }
					: "",
			],
		},
	];

	const [navigation, setNavigation] = useState(initialNavigation);

	const handleClick = (name) => {
		setNavigation((prevNavigation) =>
			prevNavigation.map((item) =>
				item.name === name
					? { ...item, current: true }
					: { ...item, current: false },
			),
		);
	};

	const handleLogout = () => {
		// Remove profile from local storage
		localStorage.removeItem("profile");
		window.location.href = "/login";
	};

	return (
		<>
			{/* Mobile toggle button */}
			<div className="lg:hidden">
				<button
					onClick={() => setSidebarOpen(!sidebarOpen)}
					className="bg-gray-200 p-1"
				>
					{sidebarOpen ? (
						<XMarkIcon className="w-6 h-6" />
					) : (
						<Bars3Icon className="w-6 h-6" />
					)}
				</button>
			</div>

			{/* Sidebar */}
			<div
				className={`fixed inset-y-0 left-0 transform ${
					sidebarOpen ? "translate-x-0" : "-translate-x-full"
				} lg:translate-x-0 lg:static lg:inset-0 transition-transform duration-200 ease-in-out z-30 w-72 sidebar dark:bg-secondary-dark-bg bg-white`}
			>
				<div className="flex z-20 grow justify-between flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 h-screen">
					<div>
						<div className="flex h-16 shrink-0 items-center">
							<Link to="/">
								<h1 className="text-4xl text-center font-bold text-orange-400">
									Cherio
								</h1>
							</Link>
							{sidebarOpen ? (
								<button
									onClick={() => setSidebarOpen(!sidebarOpen)}
									className="lg:hidden ml-12 flex rounded-full"
								>
									<XMarkIcon className="w-6 h-6" />
								</button>
							) : (
								""
							)}
						</div>
						<nav className="flex flex-1 flex-col">
							<ul role="list" className="flex flex-1 flex-col gap-y-7">
								<li>
									{navigation.map((item) => (
										<li key={item.name}>
											{!item.children ? (
												<a
													href={item.href}
													onClick={() => handleClick(item.name)}
													className={classNames(
														item.current ? "bg-gray-50" : "hover:bg-gray-50",
														"group flex gap-x-3 rounded-md p-2 my-4 text-sm leading-6 font-semibold text-gray-700",
													)}
												>
													<item.icon
														className="h-6 w-6 shrink-0 text-gray-400"
														aria-hidden="true"
													/>
													{item.name}
												</a>
											) : (
												<Disclosure as="div">
													{({ open }) => (
														<>
															<Disclosure.Button
																className={classNames(
																	item.current
																		? "bg-gray-50"
																		: "hover:bg-gray-50",
																	"flex items-center w-full my-4 text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700",
																)}
																onClick={() => handleClick(item.name)}
															>
																<item.icon
																	className="h-6 w-6 shrink-0 text-gray-400"
																	aria-hidden="true"
																/>
																{item.name}
																<ChevronRightIcon
																	className={classNames(
																		open
																			? "rotate-90 text-gray-500"
																			: "text-gray-400",
																		"ml-auto h-5 w-5 shrink-0",
																	)}
																	aria-hidden="true"
																/>
															</Disclosure.Button>
															<Disclosure.Panel as="ul" className="mt-1 px-2">
																{item.children.map((subItem) => (
																	<li key={subItem.name}>
																		<Disclosure.Button
																			as="a"
																			href={subItem.href}
																			className={classNames(
																				subItem.current
																					? "bg-gray-50"
																					: "hover:bg-gray-50",
																				"block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700",
																			)}
																		>
																			{subItem.name}
																		</Disclosure.Button>
																	</li>
																))}
															</Disclosure.Panel>
														</>
													)}
												</Disclosure>
											)}
										</li>
									))}
								</li>
							</ul>
						</nav>
					</div>
					<div className="flex flex-col mb-6">
						{/* <div className="flex items-center px-2">
							<div className="flex items-center justify-center mr-2 flex-shrink-0 h-12 w-12 rounded-full bg-blue-100 text-blue-500">
								<span>AR</span>
							</div>
							<span>Hi, Samantha</span>
						</div> */}
						<button
							onClick={handleLogout}
							className="py-2 px-4 rounded-xl my-6 border-2 border-blue"
						>
							Logout
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
