import axios from "axios";

const API = axios.create({
	baseURL: "https://cherio-track-prod-fcbbe03ffafc.herokuapp.com",
});

API.interceptors.request.use((req) => {
	if (localStorage.getItem("profile")) {
		req.headers.Authorization = `Bearer ${
			JSON.parse(localStorage.getItem("profile")).access_token
		}`;
	}

	return req;
});

export const fetchFarmers = () => API.get("/farmer/list-view");

export const fetchGroups = () => API.get("/farmer/farmer-groups");
export const fetchFacilities = () => API.get("/account/processing-facilities");

export const createFarmer = (newFarmer) => {
	let formData = new FormData();
	formData.append("first_name", newFarmer.first_name);
	formData.append("last_name", newFarmer.last_name);
	formData.append("sex", newFarmer.sex);
	formData.append("date_of_birth", newFarmer.date_of_birth);
	formData.append("email", newFarmer.email);
	formData.append("phone_number", newFarmer.phone_number);
	formData.append("national_id", newFarmer.national_id);
	formData.append("country", newFarmer.country);
	formData.append("district", newFarmer.district);
	formData.append("county", newFarmer.county);
	formData.append("sub_county", newFarmer.sub_county);
	formData.append("parish", newFarmer.parish);
	formData.append("village", newFarmer.village);
	formData.append("farmer_image", newFarmer.display_picture);
	formData.append("date_of_registration", newFarmer.date_of_registration);
	formData.append(
		"household_members",
		newFarmer.household_members ? newFarmer.household_members : "",
	);
	formData.append(
		"total_household_income",
		newFarmer.total_household_income ? newFarmer.total_household_income : "",
	);
	formData.append(
		"school_age_household_members",
		newFarmer.school_age_household_members
			? newFarmer.school_age_household_members
			: "",
	);
	formData.append(
		"hazardous_prohibition",
		newFarmer.hazardous_prohibition ? newFarmer.hazardous_prohibition : "",
	);
	formData.append("hunger_days", newFarmer.hunger ? newFarmer.hunger : "");
	let token = JSON.parse(localStorage.getItem("profile")).access_token;
	// console.log("tok", token);
	// console.log("form", formData);

	return API.post("farmer/add-farmer", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const createFarm = (id, newFarm) =>
	API.post(`/farmer/add-farm/${id}`, newFarm);
export const farmerInfo = (id) => API.get(`/farmer/farmer-info/${id}`);
export const mapView = () => API.get("/farmer/map-view");
export const deleteFarmer = (id) => API.delete(`/farmer/delete-farmer/${id}`);

export const orgRegister = (formData) =>
	API.post("/track/organizations/create/", formData);

export const signin = (formData) => API.post("/auth/login", formData);

export const signup = (formData) => API.post("/auth/register", formData);

export const viewCompanyEmployees = async () => {
	return await API.get("/account/view-users");
};
export const confirmInvite = (userData, token, navigate) => {
	try {
		API.post(`/account/join-organization/${token}`, userData);
		// console.log("successs");
		navigate("/login");
	} catch (error) {
		return console.log(error);
	}
};

export const inviteUser = (userData) =>
	API.post("/account/invite-user", userData);

export const deactivateUser = (id) => API.get(`/account/deactivate-user/${id}`);
export const reactivateUser = (id) => API.get(`/account/reactivate-user/${id}`);

export const updateUser = (id, userData) =>
	API.patch(`/account/update-user/${id}`, userData);

export const viewUser = () => API.get(`/account/view-user`);

export const viewOrganization = () => API.get(`/account/organization-info`);

export const viewInvitedUsers = () => API.get(`/account/invited-users`);

export const updateOrganization = (orgData) =>
	API.post(`/account/update-organization`, orgData);

export const createNewFarmerGroup = (groupData) =>
	API.post(`/account/farmer-group`, groupData);

export const createProcessingFacility = (facilityData) =>
	API.post(`/account/processing-facility`, facilityData);

export const getFarmsLinkedToFarmer = async (farmer_id) => {
	return await API.get(`/farmer/farmer-farms/${farmer_id}`);
};

export const postCertification = (certInfo, farmer_id) => {
	let formData = new FormData();
	formData.append("certificate_name", certInfo.certificate_name);
	formData.append("certification_date", certInfo.certification_date);
	formData.append("farm_id", certInfo.farm_id);
	formData.append("certificate_id_number", certInfo.certificate_id_number);
	formData.append("certificate_image", certInfo.certificate_image);

	let token = JSON.parse(localStorage.getItem("profile")).access_token;
	// console.log("tok", token);
	// console.log("form", formData);

	return API.post(`/farmer/add-certificate/${farmer_id}`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteCertificate = async (cert_id) =>
	await API.delete(`/farmer/remove-certificate/${cert_id}`);

export const getCertificates = async (farmer_id) =>
	await API.get(`/farmer/list-certificates/${farmer_id}`);

export const updateFarmer = async (farmer_id, farmerDetails) => {
	let formData = new FormData();
	formData.append("first_name", farmerDetails.first_name);
	formData.append("last_name", farmerDetails.last_name);
	formData.append("sex", farmerDetails.sex);
	formData.append("date_of_birth", farmerDetails.date_of_birth);
	formData.append("email", farmerDetails.email);
	formData.append("phone_number", farmerDetails.phone_number);
	formData.append("national_id", farmerDetails.national_id);
	formData.append("district", farmerDetails.district);
	formData.append("county", farmerDetails.county);
	formData.append("sub_county", farmerDetails.sub_county);
	formData.append("parish", farmerDetails.parish);
	formData.append("village", farmerDetails.village);
	formData.append(
		"farmer_image",
		farmerDetails.display_picture ? farmerDetails.display_picture : "",
	);
	formData.append("date_of_registration", farmerDetails.date_of_registration);
	formData.append(
		"household_members",
		farmerDetails.household_members ? farmerDetails.household_members : "",
	);
	formData.append(
		"total_household_income",
		farmerDetails.total_household_income
			? farmerDetails.total_household_income
			: "",
	);
	formData.append(
		"school_age_household_members",
		farmerDetails.school_age_household_members
			? farmerDetails.school_age_household_members
			: "",
	);
	formData.append(
		"hazardous_prohibition",
		farmerDetails.hazardous_prohibition
			? farmerDetails.hazardous_prohibition
			: "",
	);
	formData.append(
		"hunger_days",
		farmerDetails.hunger ? farmerDetails.hunger : "",
	);

	let token = JSON.parse(localStorage.getItem("profile")).access_token;

	return API.post(`/farmer/update-farmer/${farmer_id}`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const uploadPolygonFile = async (polygonData) => {
	let formData = new FormData();
	formData.append("polygon_file", polygonData.polygonFile.polygon_file);
	let token = JSON.parse(localStorage.getItem("profile")).access_token;
	console.log("data", polygonData);

	return await API.post(`farmer/add-polygon/${polygonData.farmId}`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	});
};
