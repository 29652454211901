import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MapComponent from "./Map";
import { useStateContext } from "../contexts/ContextProvider";
import Navbar from "./Navbar";
import { getFarmer } from "../actions/farmers";
import { useDispatch, useSelector } from "react-redux";
import NewSideBar from "./NewSideBar";

const FarmerDetails = () => {
	const { activeMenu } = useStateContext();
	const farmerData = useSelector((state) => state.farmers);
	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getFarmer(id));
	}, [id, dispatch]);

	const farmer = farmerData.data;

	return (
		<>
			{activeMenu && (
				<div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white left-0 top-0">
					<NewSideBar />
				</div>
			)}
			<div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
				<Navbar />
			</div>

			<div className="px-12">
				<div className="px-4 sm:px-0">
					<h3 className="text-base font-semibold leading-7 text-gray-900">
						Farmer Information
					</h3>
					<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
						Personal details and application.
					</p>
				</div>
				<div className="mt-6 border-t border-gray-100">
					<dl className="divide-y divide-gray-100">
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Farmer Name
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								{farmer.first_name} {farmer.last_name}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Phone Number
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								{farmer.phone_number}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								District
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								{farmer.district}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Location
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								{farmer.farms[0].point_coordinate.lat},{" "}
								{farmer.farms[0].point_coordinate.lng}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Date of Birth
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								{farmer.date_of_birth}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Location Map
							</dt>
							<dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
								<MapComponent
									selectedLocation={farmer.farms[0].point_coordinate}
								/>
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</>
	);
};

export default FarmerDetails;
