import React, { useState, useEffect, useCallback, useRef } from "react";
import { GoogleMap, useLoadScript, Polygon } from "@react-google-maps/api";
import { REACT_APP_GOOGLE_MAPS_KEY } from "../constants/constants";

const libraries = ["drawing"];

function ProfileMap({ polygonCoords, pointCords, ifSelected }) {
	const [polygon, setPolygon] = useState([]);
	useEffect(() => {
		if (ifSelected) {
			setPolygon(polygonCoords);
		} else {
			setPolygon([]);
		}
	}, [ifSelected, polygonCoords]);

	const containerStyle = {
		width: "100%",
		height: "100%",
	};
	const mapRef = useRef(null);

	const [mapKey, setMapKey] = useState(Date.now());
	const [mapCenter, setMapCenter] = useState(pointCords);

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
		libraries: libraries,
	});

	const onLoad = useCallback((mapInstance) => {
		mapRef.current = mapInstance;
	}, []);

	if (loadError) {
		return <div>Error loading maps</div>;
	}

	return (
		<div style={{ width: "100%", height: "400px" }}>
			{isLoaded ? (
				<GoogleMap
					key={mapKey}
					mapContainerStyle={containerStyle}
					center={mapCenter}
					zoom={17}
					onLoad={onLoad}
					mapTypeId="satellite"
				>
					<Polygon
						path={polygon}
						options={{
							fillColor: "#ffff00",
							fillOpacity: 0.5,
							strokeWeight: 2,
							clickable: true,
							editable: false,
							draggable: false,
							zIndex: 1,
						}}
					/>
				</GoogleMap>
			) : (
				<div>Loading...</div>
			)}
		</div>
	);
}

export default ProfileMap;
