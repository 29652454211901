import React, { useEffect, useState } from "react";
import NewSideBar from "../components/NewSideBar";
import { Link } from "react-router-dom";
import { viewInvitedUsers } from "../api";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function Invitations() {
	const [isLoading, setIsLoading] = useState(true);
	const [invitationList, setInvitationList] = useState([]);

	const fetchInvitedUsers = async () => {
		try {
			const response = await viewInvitedUsers();
			setInvitationList(response.data.users);
			setIsLoading(false);
			// console.log(response.data);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		fetchInvitedUsers();
	}, []);
	return (
		<>
			<div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white left-0 top-0">
				<NewSideBar />
			</div>
			<div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
				<div class="pt-4 pl-4 pb-4">
					<p class="font-bold text-5xl pl-2 text-sky-800">Invitations</p>
				</div>
				<div>
					<div className="px-4 sm:px-6 lg:px-8">
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h1 className="text-base font-semibold leading-6 text-sky-800">
									A list of all the users in you have invited.
								</h1>
							</div>
							<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
								<Link to="/employees">
									<button
										type="button"
										className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										View all employees
									</button>
								</Link>
							</div>
						</div>
						<div className="mt-8 flow-root">
							<div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
								<div className="inline-block min-w-full py-2 align-middle">
									<table className="min-w-full border-separate border-spacing-0">
										<thead>
											<tr>
												<th
													scope="col"
													className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter sm:table-cell"
												>
													Email
												</th>

												<th
													scope="col"
													className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-sky-800 backdrop-blur backdrop-filter"
												>
													Role
												</th>
											</tr>
										</thead>
										{isLoading ? (
											"Loading..."
										) : (
											<tbody>
												{invitationList?.map((person, personIdx) => (
													<tr key={person.email}>
														<td
															className={classNames(
																personIdx !== invitationList.length - 1
																	? "border-b border-gray-200"
																	: "",
																"hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell",
															)}
														>
															{person.email}
														</td>

														<td
															className={classNames(
																personIdx !== invitationList.length - 1
																	? "border-b border-gray-200"
																	: "",
																"whitespace-nowrap px-3 py-4 text-sm text-gray-500",
															)}
														>
															{person.role}
														</td>
													</tr>
												))}
											</tbody>
										)}
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Invitations;
