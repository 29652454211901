import React, { useEffect } from 'react';
import { useStateContext } from '../contexts/ContextProvider';

const NotFoundPage = () => {

    const { setActiveMenu } = useStateContext();

    useEffect(() => {
      setActiveMenu(false);
    }, [])
  return (
    <div className="items-center justify-center min-h-screen">
      <h1 className="text-4xl font-bold">404 - Page Not Found</h1>
    </div>
  );
};

export default NotFoundPage;