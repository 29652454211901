import React, { useEffect } from 'react'
import { useStateContext } from '../contexts/ContextProvider';
import OrgRegister from '../user/OrgRegister';


const LoginPage = () => {
  const { setActiveMenu } = useStateContext();

  useEffect(() => {
    setActiveMenu(false);
  }, [])
  return (
    <div className=''>
        <OrgRegister />
    </div>
  )
}

export default LoginPage