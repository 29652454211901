import React from 'react'
import { useSelector } from 'react-redux';
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';

const Home = () => {
    const admin = localStorage.getItem("profile")
    const Admin = useSelector((state) => state.auth.authData);
    // console.log(admin);
    return (
        <div className="admin-container">
            {Admin || admin ? <Dashboard /> : <LoginPage />}
        </div>
    )
}

export default Home