function TemplatePointers() {
    return (
        <div className="pl-24 pt-2">
            <h1 className="text-2xl mt-8 font-bold text-sky-800">Cherio Admin Features</h1>
            <p className="py-2 mt-4"> <span className="text-neutral-500 font-extrabold">✓</span>
                <span className="font-semibold pl-4 text-sky-800">Dashboard and Analytics</span>
            </p>
            <p className="py-2 "><span className="text-neutral-500 font-extrabold">✓</span>
                <span className="font-semibold pl-4 text-sky-800">Data Security and Validation</span>
            </p>
            <p className="py-2"><span className="text-neutral-500 font-extrabold">✓</span>
                <span className="font-semibold pl-4 text-sky-800">Cherio Track and Mapping </span>
            </p>
            <p className="py-2  "><span className="text-neutral-500 font-extrabold">✓</span>
                <span className="font-semibold pl-4 text-sky-800">Report Creation and Export </span>
            </p>
            <p className="py-2  mb-4"><span className="text-neutral-500 font-extrabold">✓</span>
                <span className="font-semibold pl-4 text-sky-800">Custom Roles and Permissions</span>
            </p>
        </div>
    )
}

export default TemplatePointers