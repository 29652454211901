import {
	Alert,
	AlertIcon,
	AlertDescription,
	AlertTitle,
} from "@chakra-ui/alert";

const SuccessAlert = ({ setSuccessAlert }) => {
	return (
		<div
			id="login-popup"
			tabindex="-1"
			class="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] h-full items-center justify-center popup flex"
		>
			<div class="relative p-4 w-full max-w-md h-full md:h-auto">
				<div class="bg-white relative rounded-lg shadow popup-body">
					<Alert
						status="success"
						variant="subtle"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						height="200px"
						color="green"
					>
						<AlertIcon boxSize="40px" mr={0} color="green" marginTop="25px" />
						<AlertDescription maxWidth="sm" fontSize="lg" marginTop="25px">
							Operation successful.
						</AlertDescription>
						<button
							class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-auto mb-3"
							onClick={() => setSuccessAlert(false)}
						>
							Close
						</button>
					</Alert>
				</div>
			</div>
		</div>
	);
};

export default SuccessAlert;
