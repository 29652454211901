export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";

export const CREATE_FARM = "CREATE_FARM";
export const CREATE_FARMER = "CREATE_FARMER";
export const DELETE_FARMER = "DELETE_FARMER";
export const FETCH_FARMERS = "FETCH_FARMERS";
export const MAP_VIEW = "MAP_VIEW";
export const FETCH_FARMER = "FETCH_FARMER";

export const INVITE_USER = "INVITE_USER";

export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_FACILITIES = "FETCH_FACILITIES";
