import TemplatePointers from "./components/TemplatePointers";

function LandingIntro() {
	return (
		<div className="hero min-h-full rounded-l-xl bg-base-200">
			<div className="hero-content">
				<div className="max-w-md">
					{/* Importing pointers component */}
					<TemplatePointers />
				</div>
			</div>
		</div>
	);
}

export default LandingIntro;
