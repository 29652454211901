import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadPolygonFile } from "../api";

function AddPolygon() {
	const [polygonFile, setPolygonFile] = useState(null);
	const [disableBtn, setDisableBtn] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const farmerIdFromNavigation = location?.state;
	console.log("state", farmerIdFromNavigation);

	const handleFileChange = (e) => {
		const file = e.target.files[0];

		const { name } = e.target;
		setPolygonFile((prev) => {
			return { [name]: file };
		});
	};

	const handleFileSubmission = async () => {
		const { farmer_id, farm_id } = farmerIdFromNavigation;
		try {
			setDisableBtn((prev) => true);

			await uploadPolygonFile({
				polygonFile: polygonFile,
				farmId: farm_id,
			});
			// console.log("successful");

			navigate("/add-certification", {
				state: farmer_id,
			});
		} catch (error) {
			console.log(error);
			setDisableBtn((prev) => false);
		}
	};

	return (
		<div>
			<div className="w-full px-4">
				<div className="relative w-full mb-3">
					<label className="block text-sky-800 uppercase text-blueGray-600 text-2xl font-bold mb-2 mt-8">
						Upload Polygon File
					</label>
					<input
						type="file"
						required
						name="polygon_file"
						onChange={(e) => handleFileChange(e)}
						className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					/>
				</div>
				<button
					class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 ml-10 w-3/4 mb-8"
					onClick={handleFileSubmission}
					disabled={disableBtn}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default AddPolygon;
