import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFarmers } from "../actions/farmers";

const NewFarmersList = () => {
	const dispatch = useDispatch();
	const farmers = useSelector((state) => state.farmers.farmers);
	const [isDataLoaded, setIsdataLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [currentSex, setCurrentSex] = useState("All");
	const [farmersData, setFarmersData] = useState(null);

	const [columnDefs, setColumnDefs] = useState([
		{
			field: "name",
			cellRenderer: (params) => {
				const person = {
					...params.data,
				};
				// console.log(params);

				return (
					<Link
						to="/farmer-profile"
						state={{
							person,
							personIndex: params.rowIndex,
						}}
					>
						<div className="flex items-center underline text-blue-800">
							{`${person.first_name} ${person.last_name}`}
						</div>
					</Link>
				);
			},
		},
		{ field: "phone_number" },
		{ field: "email" },
		{ field: "district" },
		{ field: "sex" },
	]);

	const defaultColDef = useMemo(() => {
		return {
			filter: "agTextColumnFilter",
		};
	}, []);

	useEffect(() => {
		dispatch(getFarmers(setIsLoading, setIsError, setIsdataLoaded));

		const transformedData = farmers?.map((farmer) => ({
			...farmer,
		}));

		setFarmersData(transformedData);
	}, [isDataLoaded]);

	return (
		<>
			<div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
				<div className="pt-4 pl-4 pb-4">
					<p className="font-bold text-5xl pl-2 text-sky-800">Farmers Table</p>
				</div>
				<div>
					<div className="px-4 sm:px-6 lg:px-8">
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h2 className="text-base font-semibold leading-6 text-sky-800">
									A list of all farmers registered under your company account.
								</h2>
							</div>

							<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex">
								<p className="mr-2 mt-2">Filter</p>
								<select
									value={currentSex}
									onChange={(e) => setCurrentSex(e.target.value)}
									className="p-1 pl-2 pr-2 bg-indigo-600 text-white rounded-md"
								>
									<option>All</option>
									<option>Male</option>
									<option>Female</option>
								</select>
							</div>
							<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
								<Link to="/add-new-farmer">
									<button
										type="button"
										className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Add farmer
									</button>
								</Link>
							</div>
						</div>
						<div className={"ag-theme-quartz mt-4"} style={{ height: 500 }}>
							<AgGridReact
								rowData={farmersData}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								rowSelection="multiple"
								suppressRowClickSelection={true}
								pagination={true}
								paginationPageSize={10}
								paginationPageSizeSelector={[10, 25, 50]}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NewFarmersList;
