import React, { useEffect } from "react";
import LineChart from "../charts/LineChart";
import DashboardTopBar from "../components/DashboardTopBar";
import { Sidebar } from "../components";
import Navbar from "../components/Navbar";
import { useStateContext } from "../contexts/ContextProvider";
import NewSideBar from "../components/NewSideBar";
import { useSelector } from "react-redux";

const Dashboard = () => {
	const { activeMenu, setActiveMenu } = useStateContext();
	const admin = localStorage.getItem("profile");
	const Admin = useSelector((state) => state.auth.authData);
	// console.log("This is my adm", admin);

	useEffect(() => {
		setActiveMenu(true);
	}, []);

	return (
		<>
			{activeMenu ? (
				<div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white left-0 top-0">
					{/* <Sidebar /> */}
					<NewSideBar />
				</div>
			) : (
				""
			)}
			{/* <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full'>
        <Navbar />
      </div> */}
			<DashboardTopBar />
			<div class="flex bg-gray-200 text-gray-800">
				<div class="p-4 w-full">
					<div class="grid grid-cols-12 gap-4">
						<div class="col-span-12 sm:col-span-6 md:col-span-3">
							<div class="flex flex-row bg-white shadow-sm rounded p-4">
								<div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-blue-100 text-blue-500">
									<svg
										class="w-6 h-6"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
										></path>
									</svg>
								</div>
								<div class="flex flex-col flex-grow ml-4">
									<div class="text-sm text-gray-500">Arabic - Kirima(KGS)</div>
									<div class="font-bold text-lg">683</div>
								</div>
							</div>
						</div>
						<div class="col-span-12 sm:col-span-6 md:col-span-3">
							<div class="flex flex-row bg-white shadow-sm rounded p-4">
								<div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-100 text-green-500">
									<svg
										class="w-6 h-6"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
										></path>
									</svg>
								</div>
								<div class="flex flex-col flex-grow ml-4">
									<div class="text-sm text-gray-500">Arabica - Nyaki (KGS)</div>
									<div class="font-bold text-lg">13,796</div>
								</div>
							</div>
						</div>
						<div class="col-span-12 sm:col-span-6 md:col-span-3">
							<div class="flex flex-row bg-white shadow-sm rounded p-4">
								<div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-orange-100 text-orange-500">
									<svg
										class="w-6 h-6"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
										></path>
									</svg>
								</div>
								<div class="flex flex-col flex-grow ml-4">
									<div class="text-sm text-gray-500">
										Robusta - Kirima (KGS)
									</div>
									<div class="font-bold text-lg">18,875</div>
								</div>
							</div>
						</div>
						<div class="col-span-12 sm:col-span-6 md:col-span-3">
							<div class="flex flex-row bg-white shadow-sm rounded p-4">
								<div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-red-100 text-red-500">
									<svg
										class="w-6 h-6"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
										></path>
									</svg>
								</div>
								<div class="flex flex-col flex-grow ml-4">
									<div class="text-sm text-gray-500">Robusta - Nyaki (KGS)</div>
									<div class="font-bold text-lg">0</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="grid lg:grid-cols-2 mt-4 grid-cols-1 gap-6">
				<LineChart />
				<LineChart />
			</div>
		</>
	);
};

export default Dashboard;
