import React from "react";

const ProfileAvatar = ({ imageUrl }) => {
	// console.log(imageUrl);
	let img = "./images/vector.jpg";
	return (
		<div className="flex items-center space-x-4">
			<img
				className="w-16 h-16 rounded-full object-cover"
				src={img}
				alt="Profile Avatar"
			/>
		</div>
	);
};

export default ProfileAvatar;
