import React, { useEffect, useState } from "react";
import MapModal from "./mapModal";
import calculateLandArea from "./../utils/calculateArea";
import { useStateContext } from "../contexts/ContextProvider";
import { useDispatch } from "react-redux";
import { createFarm } from "../actions/farmers";
import SuccessAlert from "./AddFarmSuccess";
import FailureAlert from "./FailureAlert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const AddNewFarm = ({ fetch, geoInfo, changeState }) => {
	const { polygonCoords, setPolygonCoords } = useStateContext([]);
	const { markerCoords, setMarkerCoords } = useStateContext([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [landOwnership, setLandOwnership] = useState("");
	const [landUse, setLandUse] = useState("");
	const [firstCrop, setFirstCrop] = useState("");
	const [firstCropVariety, setFirstCropVariety] = useState([]);
	const [firstVariety, setFirstVariety] = useState("");
	const [firstCropPlantingDates, setFirstCropPlantingDates] = useState({
		first_planting_date: "",
		second_planting_date: "",
	});
	const [secondCrop, setSecondCrop] = useState("");
	const [secondVariety, setSecondVariety] = useState("");
	const [secondCropVariety, setSecondCropVariety] = useState([]);
	const [secondCropPlantingDates, setSecondCropPlantingDates] = useState({
		first_planting_date: "",
		second_planting_date: "",
	});
	const [successAlert, setSuccessAlert] = useState(false);
	const [failureAlert, setFailureAlert] = useState(false);
	const [submitBtnState, setSubmitBtnState] = useState(false);
	const [firstCropCount, setFirstCropCount] = useState(0);
	const [secondCropCount, setSecondCropCount] = useState(0);

	const farmerInfo = useSelector((state) => state);

	const ArabicaVarieties = [
		"SL-28",
		"Typica",
		"Bourbon",
		"Caturra",
		"Catimor",
		"Jackson",
		"Kona",
		"Pacamara",
		"Villabos",
	];
	const RobustaVarieties = ["Erecta", "Nganda"];

	const handleFirstCropPlantingDate = (e) => {
		const { name, value } = e.target;
		setFirstCropPlantingDates((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const handleSecondCropPlantingDate = (e) => {
		const { name, value } = e.target;
		setSecondCropPlantingDates((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const handleFirstCropVariety = (event) => {
		const type = event.target.value;
		setFirstCrop(type);
		if (type === "Arabica") {
			setFirstCropVariety(ArabicaVarieties);
		} else if (type === "Robusta") {
			setFirstCropVariety(RobustaVarieties);
		} else {
			setFirstCropVariety([]);
		}
	};

	const handleSecondCropVariety = (event) => {
		const type = event.target.value;
		setSecondCrop(type);
		if (type === "Arabica") {
			setSecondCropVariety(ArabicaVarieties);
		} else if (type === "Robusta") {
			setSecondCropVariety(RobustaVarieties);
		} else {
			setSecondCropVariety([]);
		}
	};

	// useEffect(() => {
	// 	fetch();
	// }, [fetch]);

	useEffect(() => {
		if (polygonCoords.length > 0) {
			const finalArea = calculateLandArea(polygonCoords);
			setFarmDetails((prev) => {
				return {
					...prev,
					size: finalArea,
					polygon_coordinate: [...polygonCoords],
				};
			});
		}
		if (markerCoords.length > 0) {
			setFarmDetails((prev) => {
				return { ...prev, point_coordinate: markerCoords };
			});
		}
	}, [polygonCoords, markerCoords]);

	const [showModal, setShowModal] = useState(false);
	const [farmDetails, setFarmDetails] = useState({
		farm_name: "",
		land_use: "",
		point_coordinate: [],
		polygon_coordinate: [],
		size: 0,
		ownership: "",
		crops: [],
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFarmDetails((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitBtnState(true);

		const first_crop_details = () => {
			if (firstCrop) {
				return {
					crop_name: firstCrop,
					crop_variety: firstVariety,
					initial_planting_date: `${firstCropPlantingDates.first_planting_date}T14:00:00`,
					recent_planting_date: `${firstCropPlantingDates.second_planting_date}T14:00:00`,
					crop_count: Number(firstCropCount),
				};
			}
		};

		const second_crop_details = () => {
			if (secondCrop) {
				return {
					crop_name: secondCrop,
					crop_variety: secondVariety,
					initial_planting_date: `${secondCropPlantingDates.first_planting_date}T14:00:00`,
					recent_planting_date: `${secondCropPlantingDates.second_planting_date}T14:00:00`,
					crop_count: Number(secondCropCount),
				};
			}
		};

		let first_crop = first_crop_details();
		let second_crop = second_crop_details();

		let crops = [];

		if (first_crop) {
			crops.push(first_crop);
		}
		if (second_crop) {
			crops.push(second_crop);
		}
		let farmData = {
			...farmDetails,
			land_use: landUse,
			ownership: landOwnership,
			crops,
			point_coordinate: markerCoords[0],
		};

		console.log("farm", farmData);
		const farmerIdFromReducer = farmerInfo.farmers.savedFarmer?.farmer_id;
		const farmerIdFromNavigation = location?.state;

		let farmer_id;

		if (!farmerIdFromReducer) {
			farmer_id = farmerIdFromNavigation;
		} else {
			farmer_id = farmerIdFromReducer;
		}

		try {
			dispatch(
				createFarm(
					farmer_id,
					farmData,
					navigate,
					setSuccessAlert,
					setFailureAlert,
					setSubmitBtnState,
				),
			);
			// setSuccessAlert(true);
		} catch (error) {
			console.log(error);
			setSubmitBtnState(false);
			// setFailureAlert(true);
		}
	};

	return (
		<>
			<div class="w-full px-1 mx-auto bg-gray-50 flex">
				<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
					<div class="rounded-t bg-white mb-0 px-6 py-6">
						<div class="text-center flex justify-between">
							<h6 class="text-blueGray-700 text-xl font-bold">Add New Farm</h6>
						</div>
					</div>
					<div class="flex-auto px-4 lg:px-10 pt-0">
						<div class="flex flex-wrap mt-4">
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Farm Name
									</label>
									<input
										type="text"
										value={farmDetails.farm_name}
										onChange={handleChange}
										name="farm_name"
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Land Use
									</label>
									<div class="relative">
										<select
											class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-state"
											value={landUse}
											onChange={(e) => setLandUse(e.target.value)}
										>
											<option>--Select land use--</option>
											<option>Single</option>
											<option>Mixed</option>
										</select>
										<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												class="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
							</div>

							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Size in Square Meters
									</label>
									<input
										type="text"
										value={farmDetails.size}
										name="size"
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Ownership
									</label>
									<div class="relative">
										<select
											class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-state"
											value={landOwnership}
											onChange={(e) => setLandOwnership(e.target.value)}
										>
											<option>--Select Land ownership--</option>
											<option>Leased</option>
											<option>Owned</option>
											<option>Cooperative</option>
											<option>Community</option>
										</select>
										<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												class="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>
								</div>
							</div>

							<div class="w-full lg:w-12/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Mark and draw farm boundaries
									</label>

									<button
										class="w-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-8"
										onClick={() => setShowModal(true)}
									>
										Click to Open Map
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="flex-auto px-4 lg:px-10 pt-0">
						<div class="flex flex-wrap mt-4">
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Latitude
									</label>
									<input
										type="text"
										value={markerCoords[0]?.lat}
										name="latitude"
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-6/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Longitude
									</label>
									<input
										type="text"
										value={markerCoords[0]?.lng}
										name="longitude"
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
						</div>
					</div>
					<p class="text-blueGray-700 text-xl font-bold ml-14 py-1">Add Crop</p>
					<div class="flex-auto px-4 lg:px-10 pt-0">
						<div class="flex flex-wrap">
							<div class="w-full lg:w-3/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										First Crop
									</label>
									<select
										class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="grid-state"
										value={firstCrop}
										onChange={handleFirstCropVariety}
										required
									>
										<option value="">--Select Type--</option>
										<option value="Arabica">Arabica</option>
										<option value="Robusta">Robusta</option>
									</select>
									<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg
											class="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 10"
										>
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
										</svg>
									</div>
								</div>
							</div>
							<div class="w-full lg:w-3/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										First Planting Date
									</label>
									<input
										type="date"
										name="first_planting_date"
										value={firstCropPlantingDates?.first_planting_date}
										onChange={handleFirstCropPlantingDate}
										required
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-3/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Second Planting Date
									</label>
									<input
										type="date"
										name="second_planting_date"
										value={firstCropPlantingDates?.second_planting_date}
										onChange={handleFirstCropPlantingDate}
										required
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-3/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Crop Count
									</label>
									<input
										type="number"
										name="crop_count"
										value={firstCropCount}
										onChange={(e) =>
											setFirstCropCount((prev) => e.target.value)
										}
										required
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="w-full lg:w-11/12 px-4 ml-5">
						<div class="relative w-full mb-3 ml-4">
							<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
								FIRST CROP VARIETY
							</label>
							<select
								class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-state"
								disabled={!firstCrop}
								value={firstVariety}
								onChange={(e) => setFirstVariety(e.target.value)}
								required
							>
								<option value="">--Select Variety--</option>
								{firstCropVariety.map((variety, index) => (
									<option key={index} value={variety}>
										{variety}
									</option>
								))}
							</select>
							<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg
									class="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
								</svg>
							</div>
						</div>
					</div>
					<div class="flex-auto px-4 lg:px-10 pt-0">
						<div class="flex flex-wrap">
							<div class="w-full lg:w-3/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Second Crop
									</label>
									<select
										class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="grid-state"
										value={secondCrop}
										onChange={handleSecondCropVariety}
									>
										<option value="">--Select Type--</option>
										<option value="Arabica">Arabica</option>
										<option value="Robusta">Robusta</option>
									</select>
									<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg
											class="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 10"
										>
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
										</svg>
									</div>
								</div>
							</div>
							<div class="w-full lg:w-3/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										First Planting Date
									</label>
									<input
										type="date"
										name="first_planting_date"
										value={secondCropPlantingDates?.first_planting_date}
										onChange={handleSecondCropPlantingDate}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-3/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Second Planting Date
									</label>
									<input
										type="date"
										name="second_planting_date"
										value={secondCropPlantingDates?.second_planting_date}
										onChange={handleSecondCropPlantingDate}
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
							<div class="w-full lg:w-3/12 px-4">
								<div class="relative w-full mb-3">
									<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
										Crop Count
									</label>
									<input
										type="number"
										name="crop_count"
										value={secondCropCount}
										onChange={(e) =>
											setSecondCropCount((prev) => e.target.value)
										}
										required
										class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="w-full lg:w-11/12 px-4 ml-5">
						<div class="relative w-full mb-3 ml-4">
							<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
								SECOND CROP VARIETY
							</label>
							<select
								class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-state"
								disabled={!secondCrop}
								value={secondVariety}
								onChange={(e) => setSecondVariety(e.target.value)}
							>
								<option value="">--Select Variety--</option>
								{secondCropVariety.map((variety, index) => (
									<option key={index} value={variety}>
										{variety}
									</option>
								))}
							</select>
							<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg
									class="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
								</svg>
							</div>
						</div>
					</div>

					<button
						class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 ml-14 mr-14 mb-8"
						onClick={handleSubmit}
						// disabled={submitBtnState}
					>
						Submit
					</button>
				</div>
			</div>
			{failureAlert ? <FailureAlert setFailureAlert={setFailureAlert} /> : ""}
			{successAlert ? <SuccessAlert setSuccessAlert={setSuccessAlert} /> : ""}
			<MapModal showModal={showModal} setShowModal={setShowModal} />
		</>
	);
};

export default AddNewFarm;
