import { mapView } from "../api";
import {
  FETCH_FARMER,
  FETCH_FARMERS,
  DELETE_FARMER,
  MAP_VIEW,
  CREATE_FARM,
  CREATE_FARMER,
  FETCH_GROUPS,
  FETCH_FACILITIES,
} from "../constants/actionTypes";

// Define the initial state object
const initialState = {
  farmers: [],
  groups: [],
  facilities: [],
  mapView: [],
  savedFarmer: null,
  savedFarm: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FARMER:
      return { ...state, farmers: action.payload };
    case FETCH_FARMERS:
      return { ...state, farmers: action.payload };
      case FETCH_GROUPS:
        return { ...state, groups: action.payload };
      case FETCH_FACILITIES:
        return { ...state, facilities: action.payload };
    case DELETE_FARMER:
      // return farmers.filter((farmer) => farmer.id !== action.payload);
      return { 
        ...state, 
        farmers: state.farmers.filter((farmer) => farmer.id !== action.payload) 
      };
    case MAP_VIEW:
      return { ...state, mapView: action.payload };
    case CREATE_FARM:
      return {...state, savedFarm: action.payload};
    case CREATE_FARMER:
      return {...state, savedFarmer: action.payload };
    default:
      return state;
  }
};

export default reducer;
