import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import LandingIntro from '../user/LandingIntro';

const SignupRedirect = () => {
  const { setActiveMenu } = useStateContext();

  useEffect(() => {
    setActiveMenu(false);
  }, [])
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-base-200 flex items-center p-24">
      <div className="card mx-auto w-full max-w-5xl shadow-xl ">
        <div className="grid  md:grid-cols-2 grid-cols-1 bg-base-100 rounded-xl">
          <div className='p-10'>
            <LandingIntro />
          </div>
          <div className="flex flex-col items-center pt-24 bg-gray-100">
            <p className="text-lg text-center my-10">
              Thank you for signing up! Please check your email to confirm your account before you can sign in.
            </p>
            <button
              className="px-4 py-2 bg-sky-800 text-white rounded hover:bg-blue-700"
              onClick={() => navigate('/login')}
            >
              Go to Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupRedirect;
