import React from 'react'
import ForgotPassword from '../user/ForgotPassword'

const ForgotPasswordPage = () => {
  return (
    <div>
        <ForgotPassword />
    </div>
  )
}

export default ForgotPasswordPage